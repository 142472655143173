import Logo from "./logo.png";
import InstallersHero from "./installers-hero.png";
import Installer1 from "./installer1.png";
import Installer2 from "./installer2.png";
import Installer3 from "./installer3.png";
import Installer4 from "./installer4.png";
import ProfileBanner from "./profile-banner.png";
import Back from "./back.png";
import Whatsapp from "./whatsapp.png";
import Linkedin from "./linkedin.png";
import Youtube from "./youtube.png";
import Facebook from "./facebook.png";
import Twitter from "./twitter.png";
import Instagram from "./instagram.png";
import CheckCircle from "./check-circle.png";
import Battery from "./battery.png";
import Panel from "./panel.png";
import Inverter from "./inverter.png";
import MedalStar from "./medal-star.png";
import CheckFill from "./check-fill.png";
import DefaultImage from "./defaultImage.png";
import EmptyOrder from "./empty-order.png";
import PackageDetailsModal from "./package-details.png";
import ThankYouCheck from "./thank-you-check.png";
import Bank from "./bank.png";
import PaymentOptions from "./paymentOptions.webp";
import Paystack from "./paystack.png";
import Banner1 from "./banner1.jpg";
import Banner2 from "./banner2.jpg";
import Banner3 from "./banner3.jpg";
import Banner4 from "./banner4.jpg";
import About5 from "./banner5.jpg";
import Banner6 from "./banner6.jpg";
import Banner7 from "./banner7.jpg";
import Banner8 from "./banner8.jpg";
import Banner9 from "./banner9.jpg";
import Banner10 from "./banner10.jpg";
import Banner11 from "./banner11.jpg";
import About1 from "./about1.jpg";
import About2 from "./about2.jpg";
import About3 from "./about3.jpg";
import About4 from "./about4.jpg";
import ChargeController from "./charge_controller.png";
import Toolbox from "./toolbox.png";
export const aboutImagesUrl = [About1, About2, About3, About4, About5];

export const expertiseImagesUrl = [
  Banner6,
  Banner7,
  Banner8,
  Banner9,
  Banner10,
  Banner11,
];

const imagesUrl = {
  Logo,
  InstallersHero,
  Installer1,
  Installer2,
  Installer3,
  Installer4,
  ProfileBanner,
  Back,
  Whatsapp,
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  Youtube,
  CheckCircle,
  CheckFill,
  Panel,
  Battery,
  Inverter,
  MedalStar,
  DefaultImage,
  EmptyOrder,
  PackageDetailsModal,
  ThankYouCheck,
  Bank,
  PaymentOptions,
  Paystack,
  ChargeController,
  Toolbox,
};

export const bannerImagesUrl = [Banner1, Banner2, Banner3, Banner4];

export default imagesUrl;
