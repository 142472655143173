import React, { useEffect, useState } from "react";
import { Button } from "../../../../components/ui/button";
import { useNavigate, useParams } from "react-router-dom";
import { IdState, useIdStore } from "../../../../store/customer-store";
import { aboutImagesUrl } from "../../../../assets/images/imagesUrl";
import MaxWidthWrapper from "./max-width-wrapper";

const AboutUs = ({
  installerName,
  about,
  phone,
}: {
  installerName: string;
  about: string;
  phone: string;
}) => {
  const navigate = useNavigate();
  const setInstallerId = useIdStore((state: IdState) => state.setInstallerId);
  const packageId = useIdStore((state: IdState) => state.readPackageId());
  const [randomFeatures, setRandomFeatures] = useState<
    { title: string; description: string }[]
  >([]);
  const { installerId } = useParams<{ installerId: string }>();

  const handleOrderPackage = () => {
    setInstallerId(installerId ?? "");

    if (packageId) {
      navigate("/select-payment-plan");
    } else {
      navigate("/order-a-system");
    }
  };

  // State for dynamically selected image
  const [selectedImage, setSelectedImage] = useState<string>("");

  // Select a random image from the array when the component loads
  useEffect(() => {
    if (aboutImagesUrl.length > 0) {
      const randomIndex = Math.floor(Math.random() * aboutImagesUrl.length);
      setSelectedImage(aboutImagesUrl[randomIndex]);
    }

    // Select 3 random features
    const shuffledFeatures = [...features].sort(() => Math.random() - 0.5);
    setRandomFeatures(shuffledFeatures.slice(0, 3));
  }, []);

  const features = [
    {
      title: "Customized Solutions",
      description:
        "We design solar systems specifically to match your energy consumption and budget, ensuring maximum efficiency and savings.",
    },
    {
      title: "Affordable Financing",
      description:
        "Our financing options make solar power accessible, allowing you to enjoy clean energy without breaking the bank.",
    },
    {
      title: "Premium Equipment",
      description:
        "We use state-of-the-art solar panels and inverters that guarantee durability and high energy output.",
    },
    {
      title: "Expert Installation",
      description:
        "Our team of certified technicians ensures safe and efficient installation, giving you peace of mind.",
    },
    {
      title: "24/7 Support",
      description:
        "Our dedicated customer support team is available round the clock to assist with any issues or questions.",
    },
    {
      title: "Energy Independence",
      description:
        "Switching to solar reduces your carbon footprint, helping create a sustainable future for everyone.",
    },
    {
      title: "Fast Installation",
      description:
        "We prioritize efficiency, completing installations within days so you can start saving sooner.",
    },
    {
      title: "Energy Monitoring",
      description:
        "Our systems come with smart monitoring tools, allowing you to view your energy production in real time.",
    },
  ];

  return (
    <section
      id="about-us-section"
      className="overflow-hidden bg-white mt-8 sm:mt-20 "
    >
      <MaxWidthWrapper className="">
        <div className="mx-auto max-w-7xl ">
          <div className="mx-auto grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0  lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-sm  leading-7 text-[#012B27]">
                  {installerName}
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  About Us
                </p>
                <p className="mt-6 text-[0.9rem] text-[#555555] leading-8 ">
                  {about}
                </p>
                <dl className="mt-4 max-w-xl space-y-8 text-base leading-7 text-[#555555] lg:max-w-none">
                  {randomFeatures.map((feature, index) => (
                    <div key={index} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                          className="absolute left-1 top-1 h-5 w-5 text-[#012B27] "
                        >
                          <path d="M3.196 12.87l-.825.483a.75.75 0 000 1.294l7.25 4.25a.75.75 0 00.758 0l7.25-4.25a.75.75 0 000-1.294l-.825-.484-5.666 3.322a2.25 2.25 0 01-2.276 0L3.196 12.87z"></path>
                          <path d="M3.196 8.87l-.825.483a.75.75 0 000 1.294l7.25 4.25a.75.75 0 00.758 0l7.25-4.25a.75.75 0 000-1.294l-.825-.484-5.666 3.322a2.25 2.25 0 01-2.276 0L3.196 8.87z"></path>
                          <path d="M10.38 1.103a.75.75 0 00-.76 0l-7.25 4.25a.75.75 0 000 1.294l7.25 4.25a.75.75 0 00.76 0l7.25-4.25a.75.75 0 000-1.294l-7.25-4.25z"></path>
                        </svg>
                        <span className="text-[0.9rem]">
                          {feature.title} -{" "}
                        </span>
                      </dt>
                      <dd className="inline text-[0.9rem] text-[#555555]">
                        {feature.description}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
              <div className="mt-5 flex items-center gap-x-6">
                <Button
                  onClick={handleOrderPackage}
                  size={"lg"}
                  className="bg-[#3EB555] hover:bg-[#359C4A] "
                >
                  Order Now
                </Button>
              </div>
            </div>
            {selectedImage && (
              <img
                src={selectedImage}
                alt="About Us"
                className="object-cover max-w-full h-full rounded-xl shadow-xl ring-1 ring-gray-400/10"
              />
            )}
          </div>
        </div>
      </MaxWidthWrapper>
    </section>
  );
};

export default AboutUs;
