import MaxWidthWrapper from "./max-width-wrapper";
import imagesUrl from "../../../../assets/images/imagesUrl";
import { Button } from "../../../../components/ui/button";
import { cn } from "../../../../lib/utils";
import { MobileaNavSheet } from "./mobile-nav";
import { useNavigate } from "react-router-dom";

export type Category = {
  id: string;
  title: string;
  description: string;
  images: string[];
  displayLocation: string;
  image: string;
};

const NavbarClient = ({
  image,
  installerName,
}: {
  image: string;
  installerName: string;
}) => {
  const navigate = useNavigate();

  return (
    <div id="navbar" className="bg-white  sticky top-0 z-40 inset-x-0  ">
      <header className="flex shadow-md flex-col relative bg-[#012B27] ">
        <MaxWidthWrapper>
          <div className="">
            <div className="flex h-12 md:h-16 items-center ">
              {/* TODO: Mobile menu */}
              <MobileaNavSheet installerName={installerName} image={image} />
              <div className="pointer-cursor ml-4 flex flex-col lg:ml-0">
                <span className="text-[#cccccc] text-xl font-semibold">
                  {installerName}
                </span>
              </div>
              {/* <div className="hidden z-50 lg:ml-8 lg:block lg:self-stretch">
                <NavItems />
              </div> */}

              <div className="ml-auto flex items-center ">
                <div className="hidden lg:flex flex-1 lg:items-center lg:justify-end lg:space-x-4 ">
                  <Button
                    onClick={() => {
                      const aboutUsSection =
                        document.getElementById("about-us-section");
                      if (aboutUsSection) {
                        const offsetTop = aboutUsSection.offsetTop - 30; // Adjust this value to scroll just above the top
                        window.scrollTo({
                          top: offsetTop,
                          behavior: "smooth",
                        });
                      }
                    }}
                    variant={"ghost"}
                    className={cn("text-[#cccccc]")}
                  >
                    About us
                  </Button>
                  {/* <span className="h-6 w-px bg-gray-200" aria-hidden="true" /> */}

                  <Button
                    onClick={() => {
                      const expertiseSection =
                        document.getElementById("expertise-section");
                      if (expertiseSection) {
                        const offsetTop = expertiseSection.offsetTop - 30; // Adjust this value to scroll just above the top
                        window.scrollTo({
                          top: offsetTop,
                          behavior: "smooth",
                        });
                      }
                    }}
                    variant={"ghost"}
                    className={cn("text-[#cccccc]")}
                  >
                    Our Expertise
                  </Button>
                  {/* <span className="h-6 w-px bg-gray-200" aria-hidden="true" /> */}

                  <Button
                    onClick={() => {
                      const portfolio =
                        document.getElementById("portfolio-section");
                      if (portfolio) {
                        const offsetTop = portfolio.offsetTop - 80; // Adjust this value to scroll just above the top
                        window.scrollTo({
                          top: offsetTop,
                          behavior: "smooth",
                        });
                      }
                    }}
                    variant={"ghost"}
                    className={cn("text-[#cccccc]")}
                  >
                    Our Portfolio
                  </Button>

                  {/* <span className="h-6 w-px bg-gray-200" aria-hidden="true" /> */}

                  <Button
                    onClick={() => {
                      const contactSection =
                        document.getElementById("contact-us");
                      if (contactSection) {
                        const offsetTop = contactSection.offsetTop - 30; // Adjust this value to scroll just above the top
                        window.scrollTo({
                          top: offsetTop,
                          behavior: "smooth",
                        });
                      }
                    }}
                    variant={"ghost"}
                    className={cn("text-[#cccccc]")}
                  >
                    Contact us
                  </Button>

                  {/* <span className="h-6 w-px bg-gray-200" aria-hidden="true" /> */}
                </div>

                {/* <div className="flex items-center flex-1 justify-end lg:space-x-6 lg:hidden">
                  {session ? null : (
                    <Link
                      href="/login"
                      className={buttonVariants({ variant: "ghost" })}
                    >
                      Login
                    </Link>
                  )}

                  {!session ? (
                    <span
                      className="mr-4 h-6 w-px bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="mr-4 flow-root  ">
                    <Heart
                      onClick={() => router.push("/my-account/saved-items")}
                      fill="#f97316"
                      color="#f97316"
                      className="cursor-pointer w-6 h-6"
                    />
                  </div>

                  <span
                    aria-hidden="true"
                    className={cn(
                      " h-6 w-px bg-gray-200",
                      session ? "mr-4" : ""
                    )}
                  />

                

                  {session ? (
                    <span
                      className="ml-4 h-6 w-px bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}

                  <div className="ml-4 flow-root lg:ml-6  ">
                    
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </MaxWidthWrapper>
      </header>
    </div>
  );
};

export default NavbarClient;
