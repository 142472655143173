import { Rating } from "@mui/material";
import { MapPin } from "lucide-react";
import React, { useState } from "react";
import { Button } from "./ui/button";
import { useLocation, useNavigate } from "react-router-dom";
import { Installer } from "../store/installersApiSlice";
import { IdState, useIdStore } from "../store/customer-store";

const InstallerCard = ({ installer }: { installer: Installer }) => {
  const [value, setValue] = useState<number | null>(3);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const setInstallerId = useIdStore((state: IdState) => state.setInstallerId);
  const packageId = useIdStore((state) => state.readPackageId());

  // const buttonText = pathname.startsWith("/installers")
  //   ? "Order Now"
  //   : "Select Installer";

  const buttonText = packageId ? "Select Installer" : "Order Now";

  const handleSelectInstaller = (installerId: string) => {
    setInstallerId(installerId);

    if (packageId) {
      navigate("/select-payment-plan");
    } else {
      navigate("/order-a-system");
    }
  };

  return (
    <div className="w-full relative group min-h-[30rem] flex flex-col bg-white border shadow-sm overflow-hidden rounded-2xl hover:shadow-md focus:outline-none focus:shadow-md   transition-all ease-in duration-200">
      <div className="p-4  flex-grow flex flex-col w-full relative">
        <div className="aspect-w-16 aspect-h-9 h-[183px]">
          <img
            className="h-[183px] w-full object-cover rounded-[8px]"
            src={installer?.image}
            alt="installer thumbnail"
          />
        </div>
        <div className=" mt-2 h-full flex flex-col flex-1 text-left ">
          <div className="w-full flex items-center justify-between">
            <p className="font-medium text-base">{installer?.name}</p>
            <div className="bg-[#D0D7DD80] p-1 rounded-[4px]  flex space-x-1 items-center">
              <MapPin className="w-4 h-4" />
              <span className="text-sm font-medium text-[#3C5A71]">
                {installer?.state_name}
              </span>
            </div>
          </div>
          <div className="w-full ">
            <p className="mt-2 text-sm text-[#153954] text-wrap line-clamp-3">
              {installer?.about}
            </p>
          </div>

          <div className="mt-2 flex items-center justify-between">
            <div className="flex flex-col space-y-0.5">
              <p className="text-[#808080] text-xs">Response time</p>
              <p className="text-[#2B2B2B] text-xs">
                {installer?.response_time_name}
              </p>
            </div>
            <div className="flex flex-col space-y-0.5">
              <p className="text-[#808080] text-xs">Completed projects</p>
              <p className="text-[#2B2B2B] text-xs">
                {installer?.installations}
              </p>
            </div>
          </div>

          <div className="mt-auto flex flex-col  space-y-2">
            <Button
              onClick={() => handleSelectInstaller(installer?.uniq_id)}
              className="bg-[#3EB555] hover:bg-[#359C4A] "
            >
              {buttonText}
            </Button>
            <Button
              onClick={() => navigate(`/installers/${installer?.username}`)}
              variant={"outline"}
              className="border-[#3EB555]"
            >
              View Profile
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallerCard;
