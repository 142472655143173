"use client";

import React from "react";

import { Link } from "react-router-dom";
import MaxWidthWrapper from "./max-width-wrapper";
import { Mail, Phone } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import FooterList from "./footer-list";
import { IdState, useIdStore } from "../store/customer-store";
import imagesUrl from "../assets/images/imagesUrl";

type FooterProps = {
  facebook: string;
  instagram: string;
  youtube: string;
  linkedin: string;
  twitter: string;
  whatsapp: string;
  about: string;
  installerName: string;
  phone: string;
  image: string;
};

const Footer = () => {
  const navigate = useNavigate();
  const setInstallerId = useIdStore((state: IdState) => state.setInstallerId);
  const packageId = useIdStore((state: IdState) => state.readPackageId());

  const { installerId } = useParams<{ installerId: string }>();

  const handleOrderPackage = () => {
    setInstallerId(installerId ?? "");

    if (packageId) {
      navigate("/select-payment-plan");
    } else {
      navigate("/order-a-system");
    }
  };

  return (
    <div className="  bg-[#0a1100] relative text-slate-200 text-sm ">
      {/* Add pointer-events-none to the overlay */}
      <div className="absolute z-[50] inset-0 bg-[#000000] opacity-70"></div>

      <MaxWidthWrapper className="relative z-[1000]">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-y-2 lg:gap-y-0 py-1 gap-x-3">
          <FooterList>
            <div className="mt-1 aspect-w-16 aspect-h-9 rounded-[8px] overflow-hidden h-fit sm:h-full w-[150px] relative">
              <img
                loading="eager"
                src={imagesUrl.Logo}
                alt="logo"
                height={15}
                width={123}
              />
              <div className="flex flex-col gap-3">
                <p className="text-[#cccccc]">
                  &copy; {new Date().getFullYear()} Power Ring
                </p>
              </div>
            </div>
          </FooterList>

          <FooterList>
            <h3 className="mt-1 font-semibold text-sm text-[#cccccc]">Phone</h3>

            <div className="flex flex-col gap-2 ">
              <div className="flex items-center gap-1.5">
                <Phone className="w-4 h-4" />
                <span className="">0813 736 9642</span>
              </div>
            </div>
          </FooterList>

          <FooterList className="">
            <h3 className=" mt-1 font-semibold text-sm text-[#cccccc]">
              Email
            </h3>

            <div className="flex flex-col gap-2 ">
              <div className="flex items-center gap-1.5">
                <Mail className="w-4 h-4" />
                <span className="">info@powerring.ng</span>
              </div>
            </div>
          </FooterList>

          <FooterList className="">
            <div>
              <p className="mt-1 font-semibold text-sm text-[#cccccc]">
                Connect with us
              </p>

              <div className="mt-1 flex items-center space-x-3">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/powerring_ng"
                >
                  <img src="/facebook.png" alt="twitter" className="h-6 w-6" />
                </a>

                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/powerring_ng"
                >
                  <img src="/instagram.png" alt="twitter" className="h-6 w-6" />
                </a>

                <a
                  target="_blank"
                  href="https://wa.me/2348137369642?text=Hello%20there!%20I%20would%20like%20to%20make%20an%20inquiry."
                  rel="noopener noreferrer"
                >
                  <img src="/whatsapp.png" alt="whatsapp" className="h-6 w-6" />
                </a>
              </div>
            </div>
          </FooterList>

          {/* Remaining Footer content */}
        </div>
      </MaxWidthWrapper>
    </div>
  );
};

export default Footer;
