import React from "react";
import MaxWidthWrapper from "../../../components/max-width-wrapper";

const WhyUs = () => {
  return (
    <div className=" bg-[#f7f7f7]  py-8 sm:py-16 px-10  relative flex flex-col justify-between h-full   mx-auto xl:px-0 mt-5">
      <MaxWidthWrapper>
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-1">
          Why Choose Us
        </h2>
        <p className="mb-12 text-[0.9rem] text-[#555555]">
          Here are a few of the reasons we are awesome.
        </p>
        <div className="w-full">
          <div className="flex flex-col w-full mb-10 sm:flex-row">
            <div className="w-full mb-10 sm:mb-0 sm:w-1/2">
              <div className="relative h-full ml-0 mr-0 sm:mr-10">
                <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-indigo-500 rounded-lg"></span>
                <div className="relative h-full p-5 bg-white border-2 border-indigo-500 rounded-lg">
                  <img src="service.png" alt="automaton" />

                  <div className="flex items-center -mt-1">
                    <h3 className="my-2 ml-3 text-lg font-bold text-gray-800">
                      Personalized offerings
                    </h3>
                  </div>

                  <p className="mb-2 text-[0.9rem] text-[#555555]">
                    We let you personalize your energy needs without needing
                    someone to conduct an energy audit
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2">
              <div className="relative h-full ml-0 md:mr-10">
                <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-purple-500 rounded-lg"></span>
                <div className="relative h-full p-5 bg-white border-2 border-purple-500 rounded-lg">
                  <img src="automation.png" alt="automaton" />
                  <div className="flex items-center -mt-1">
                    <h3 className="my-2 ml-3 text-lg font-bold text-gray-800">
                      Compliance
                    </h3>
                  </div>

                  <p className="mb-2 text-[0.9rem] text-[#555555]">
                    We manage compliance with your installer to ensure they
                    install what you have agreed with them. No more foul play
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full mb-5 sm:flex-row">
            <div className="w-full mb-10 sm:mb-0 sm:w-1/2">
              <div className="relative h-full ml-0 mr-0 sm:mr-10">
                <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-blue-400 rounded-lg"></span>
                <div className="relative h-full p-5 bg-white border-2 border-blue-400 rounded-lg">
                  <img src="warranty.png" alt="automaton" />

                  <div className="flex items-center -mt-1">
                    <h3 className="my-2 ml-3 text-lg font-bold text-gray-800">
                      Project Warranty Management
                    </h3>
                  </div>

                  <p className="mb-2 text-[0.9rem] text-[#555555]">
                    We manage your project warranty and guarantees with the
                    installers ensuring you have peace of mind with your solar
                    energy projects
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full mb-10 sm:mb-0 sm:w-1/2">
              <div className="relative h-full ml-0 mr-0 sm:mr-10">
                <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-yellow-400 rounded-lg"></span>
                <div className="relative h-full p-5 bg-white border-2 border-yellow-400 rounded-lg">
                  <img src="network.png" alt="automaton" />

                  <div className="flex items-center -mt-1">
                    <h3 className="my-2 ml-3 text-lg font-bold text-gray-800">
                      Extensive Network of Verified Installers
                    </h3>
                  </div>

                  <p className="mb-2 text-[0.9rem] text-[#555555]">
                    Choose from a list of over 100 verified, regulatory
                    compliant and trusted solar installers around you
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2">
              <div className="relative h-full ml-0 md:mr-10">
                <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-green-500 rounded-lg"></span>
                <div className="relative h-full p-5 bg-white border-2 border-green-500 rounded-lg">
                  <img src="maintenance.png" alt="automaton" />

                  <div className="flex items-center -mt-1">
                    <h3 className="my-2 ml-3 text-lg font-bold text-gray-800">
                      Aftersales Service Management
                    </h3>
                  </div>

                  <p className="mb-2 text-[0.9rem] text-[#555555]">
                    We handle aftersales services, so you don&apos;t have to
                    rely on a single installer
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MaxWidthWrapper>
    </div>
  );
};

export default WhyUs;
