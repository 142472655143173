import React, { useEffect, useState } from "react";
import InstallerReel from "../../../components/installer-reel";
import { useGetInstallersQuery } from "../../../store/installersApiSlice";
import { Button } from "../../../components/ui/button";

const FeaturedInstaller = () => {
  const { data, isLoading, refetch } = useGetInstallersQuery();

  const installers = data?.data || [];
  // console.log(installers, "installers");
  return (
    <>
      <InstallerReel
        installers={installers}
        title="Featured Installers"
        href="/featured-installers"
        isLoading={isLoading}
      />
    </>
  );
};

export default FeaturedInstaller;
