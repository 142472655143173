import { useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import {
  Captions,
  Counter,
  Download,
  Fullscreen,
  Thumbnails,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { Button } from "../../../../components/ui/button";
import {
  ScrollAreaForHowItWorks,
  ScrollBar,
} from "../../../../components/ui/scroll-area-works";
import MaxWidthWrapper from "./max-width-wrapper";

type InstallationProps = {
  installations:
    | {
        completion: string;
        date: string;
        id: string;
        installation_images: string[];
        installer_uniq_id: string;
        local_name: string;
        order_id: string;
        package_name: string;
        package_uniq_id: string;
        state_name: string;
      }[]
    | undefined;
};

const Installations = (props: InstallationProps) => {
  const [index, setIndex] = useState<number>(-1);
  const [imageUrls, setImageUrls] = useState<
    { src: string; title: string; description: string }[]
  >([]);

  // Ensure the installations data is valid
  const installations = props.installations || [];

  const handleViewMore = (installationIndex: number) => {
    const selectedInstallation = installations[installationIndex];
    if (selectedInstallation) {
      const formattedImageUrls = selectedInstallation.installation_images.map(
        (image, imgIndex) => ({
          src: image,
          title: selectedInstallation.package_name,
          description: `Image ${imgIndex + 1}`,
        })
      );
      setImageUrls(formattedImageUrls);
      setIndex(0); // Open lightbox at the first image
    }
  };

  if (installations.length === 0) {
    return null;
  }

  return (
    <section id="portfolio-section" className="mt-20">
      <MaxWidthWrapper>
        <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Our Portfolio
        </p>

        <ScrollAreaForHowItWorks className="mt-4 w-full whitespace-nowrap">
          <div className="flex w-max space-x-4 p-4">
            {installations.map((installation, installationIndex) => (
              <div
                key={installation.id}
                className="relative overflow-hidden rounded-lg group w-60 md:w-full"
              >
                {/* First Image */}
                <img
                  src={installation.installation_images[0]}
                  alt={installation.package_name}
                  className="w-full h-64 object-cover"
                />

                {/* Overlay with Details */}
                <div className="absolute inset-0 bg-black bg-opacity-50  transition-opacity duration-300 flex flex-col items-center justify-center text-white text-center">
                  <h3 className="text-lg font-bold">
                    {installation.package_name}
                  </h3>
                  <p className="text-sm">
                    {`${installation.local_name}, ${installation.state_name}`}
                  </p>
                  <p className="text-sm text-[#cccccc]">
                    {installation.completion}
                  </p>
                  <Button
                    onClick={() => handleViewMore(installationIndex)}
                    className="mt-4 bg-[#3EB555] hover:bg-[#359C4A] text-black"
                  >
                    View More
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollAreaForHowItWorks>
        <Lightbox
          plugins={[Captions, Download, Counter, Fullscreen, Thumbnails]}
          captions={{
            showToggle: true,
            descriptionTextAlign: "end",
          }}
          index={index}
          open={index >= 0}
          slides={imageUrls}
          close={() => setIndex(-1)}
        />
      </MaxWidthWrapper>
    </section>
  );
};

export default Installations;
