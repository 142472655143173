import imagesUrl from "../assets/images/imagesUrl";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useToggleState } from "../hooks/useToggleState";
import { Menu, X } from "lucide-react";
import { motion } from "framer-motion";
import { cn } from "../lib/utils";
import { buttonVariants } from "./ui/button";
import MaxWidthWrapper from "./max-width-wrapper";

const NavTransparent = () => {
  const location = useLocation();
  const { pathname } = location;

  const navigate = useNavigate();

  const dropDownRef = useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prev: boolean) => !prev);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const {
    state: showMenu,
    toggle,
    close: closeMobileNav,
  } = useToggleState(false);

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const mobileVariant = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 24 },
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropDownRef.current &&
      event.target instanceof Node &&
      !dropDownRef.current.contains(event.target)
    ) {
      closeMobileNav();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  return (
    <div
      className={cn(
        "z-[100]  sticky flex items-center justify-center top-[39] md:top-0 w-full h-[24px] md:h-[92px] py-[32px]",
        isScrolled ? "bg-[#012B27] top-0" : "bg-transparent"
      )}
    >
      <MaxWidthWrapper>
        <div className=" flex items-center justify-between w-full ">
          <div onClick={() => navigate("/")} className="cursor-pointer w-fit">
            <img
              loading="eager"
              src={imagesUrl.Logo}
              alt="logo"
              height={15}
              width={123}
            />
          </div>
          <div className="hidden md:flex items-center gap-[32px]">
            <Link
              to="/"
              className={cn(
                "text-gray-200 transition-all duration-150 ease-linear border-b-2 border-b-transparent hover:text-[#3EB555]  hover:border-b-[#3EB555]",
                pathname === "/" && "text-[#3EB555] border-b-[#3EB555]"
              )}
            >
              Home
            </Link>
            <Link
              to="/installers"
              className={cn(
                "text-gray-200 transition-all duration-150 ease-linear border-b-2 border-b-transparent hover:text-[#3EB555]  hover:border-b-[#3EB555]",
                pathname === "/installers" &&
                  "text-[#3EB555] border-b-[#3EB555]"
              )}
            >
              Search Installers
            </Link>
            <Link
              to="/how-it-works"
              className={cn(
                "text-gray-200 transition-all duration-150 ease-linear border-b-2 border-b-transparent hover:text-[#3EB555]  hover:border-b-[#3EB555]",
                pathname === "/how-it-works" &&
                  "text-[#3EB555] border-b-[#3EB555]"
              )}
            >
              How it works
            </Link>
            {/* <Link
              to="/about-us"
              className={cn(
                "text-gray-200 transition-all duration-150 ease-linear border-b-2 border-b-transparent hover:text-[#3EB555]  hover:border-b-[#3EB555]",
                pathname === "/about-us" && "text-[#3EB555] border-b-[#3EB555]"
              )}
            >
              About Us
            </Link> */}
          </div>
          <div className="flex items-center justify-center md:hidden">
            <div
              className={`transition-transform duration-300 ease-in-out ${
                showMenu ? "transform rotate-90" : ""
              }`}
            >
              <Menu
                onClick={toggle}
                color="#ffffff"
                className={`w-6 h-6 cursor-pointer ${
                  showMenu ? "hidden" : "block"
                }`}
              />
              <X
                color="#ffffff"
                onClick={toggle}
                className={`w-6 h-6 cursor-pointer ${
                  showMenu ? "block" : "hidden"
                }`}
              />
            </div>

            <motion.div
              initial={false}
              animate={showMenu ? "open" : "closed"}
              ref={dropDownRef}
              // className={styles.navMenu}
              style={{
                display: showMenu ? "flex" : "none",
              }}
              className="absolute top-[4rem] right-[2rem] z-[200] flex flex-col p-[10px] h-fit min-w-[240px] border bg-white rounded-lg"
            >
              <motion.ul
                variants={{
                  open: {
                    clipPath: "inset(0% 0% 0% 0% round 10px)",
                    transition: {
                      type: "spring",
                      bounce: 0,
                      duration: 0.7,
                      delayChildren: 0.3,
                      staggerChildren: 0.05,
                    },
                  },
                  closed: {
                    clipPath: "inset(10% 50% 90% 50% round 10px)",
                    transition: {
                      type: "spring",
                      bounce: 0,
                      duration: 0.3,
                    },
                  },
                }}
                style={{ pointerEvents: showMenu ? "auto" : "none" }}
              >
                <motion.li variants={mobileVariant}>
                  <Link
                    className={cn(
                      "text-[20px] font-medium  flex w-[100%] rounded cursor-pointer p-4 m-[0.25rem] hover:bg-[#92C455] bg-[#DEEDCC80]",
                      pathname === "/" && "bg-[#92C455]"
                    )}
                    onClick={closeMobileNav}
                    to="/"
                  >
                    Home
                  </Link>
                </motion.li>

                <motion.li variants={mobileVariant}>
                  <Link
                    className={cn(
                      "text-[20px] font-medium  flex w-[100%] rounded cursor-pointer p-4 m-[0.25rem] hover:bg-[#92C455] bg-[#DEEDCC80]",
                      pathname === "/installers" && "bg-[#92C455]"
                    )}
                    onClick={closeMobileNav}
                    to="/installers"
                  >
                    Search Installers
                  </Link>
                </motion.li>

                <motion.li variants={mobileVariant}>
                  <Link
                    className={cn(
                      "text-[20px] font-medium  flex w-[100%] rounded cursor-pointer p-4 m-[0.25rem] hover:bg-[#92C455] bg-[#DEEDCC80]",
                      pathname === "/how-it-works" && "bg-[#92C455]"
                    )}
                    onClick={closeMobileNav}
                    to="/how-it-works"
                  >
                    How it works
                  </Link>
                </motion.li>
              </motion.ul>
            </motion.div>
          </div>
        </div>
      </MaxWidthWrapper>
    </div>
  );
};

export default NavTransparent;
