import { ChevronsRight, Menu } from "lucide-react";

import { useWindowWidth } from "@react-hook/window-size";
import { useEffect, useState } from "react";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "../../../../components/ui/sheet";
import { Link } from "react-router-dom";
import { cn } from "../../../../lib/utils";
import { buttonVariants } from "../../../../components/ui/button";
import imagesUrl from "../../../../assets/images/imagesUrl";
import { IdState, useIdStore } from "../../../../store/customer-store";
import { useNavigate, useParams } from "react-router-dom";

export type Category = {
  id: string;
  title: string;
  description: string;
  images: string[];
  displayLocation: string;
  image: string;
};
export function MobileaNavSheet({
  image,
  installerName,
}: {
  image: string;
  installerName: string;
}) {
  const onlyWidth = useWindowWidth();
  const [showSheet, setShowSheet] = useState(false);

  const navigate = useNavigate();
  const setInstallerId = useIdStore((state: IdState) => state.setInstallerId);
  const packageId = useIdStore((state: IdState) => state.readPackageId());

  const { installerId } = useParams<{ installerId: string }>();

  useEffect(() => {
    if (onlyWidth > 768) {
      setShowSheet(false);
    }
  }, [onlyWidth]);

  const handleOrderPackage = () => {
    setInstallerId(installerId ?? "");

    if (packageId) {
      navigate("/select-payment-plan");
    } else {
      navigate("/order-a-system");
    }
  };

  return (
    <Sheet open={showSheet} onOpenChange={setShowSheet}>
      <SheetTrigger asChild>
        <button
          type="button"
          className="lg:hidden relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
        >
          <Menu className="h-6 w-6" aria-hidden="true" />
        </button>
      </SheetTrigger>

      <SheetContent className="bg-gray-50 w-[70%] " side={"left"}>
        <div className={cn("mt-6 relative flex-col  w-full  ")}>
          <p className="text-xs text-[#555555] tracking-tight ">Powered by</p>
          <div className="bg-black w-fit mt-0.5 flex py-2 px-4 rounded-lg items-center justify-start">
            <Link to="/">
              <img
                loading="eager"
                src={imagesUrl.Logo}
                alt="logo"
                height={15}
                width={123}
              />
            </Link>
          </div>
          <div>
            <div className=" pt-4 mt-1 border-t border-gray-200 mb-1">
              <p className="font-semibold text-gray-900">Categories</p>
            </div>

            <div className=" ">
              <div className="flex flex-col ">
                <Link
                  to=""
                  onClick={() => {
                    const aboutUsSection =
                      document.getElementById("about-us-section");
                    if (aboutUsSection) {
                      const offsetTop = aboutUsSection.offsetTop - 30; // Adjust this value to scroll just above the top
                      window.scrollTo({
                        top: offsetTop,
                        behavior: "smooth",
                      });
                    }
                    setShowSheet(false);
                  }}
                  className={cn(
                    buttonVariants({
                      variant: "ghost",
                      size: "sm",
                    }),

                    "dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white",
                    "justify-start capitalize"
                  )}
                >
                  <ChevronsRight className="mr-2 h-4 w-4" />
                  {`About us`}
                </Link>
                <Link
                  to=""
                  onClick={() => {
                    const expertiseSection =
                      document.getElementById("expertise-section");
                    if (expertiseSection) {
                      const offsetTop = expertiseSection.offsetTop - 30; // Adjust this value to scroll just above the top
                      window.scrollTo({
                        top: offsetTop,
                        behavior: "smooth",
                      });
                    }
                    setShowSheet(false);
                  }}
                  className={cn(
                    buttonVariants({
                      variant: "ghost",
                      size: "sm",
                    }),

                    "dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white",
                    "justify-start capitalize"
                  )}
                >
                  <ChevronsRight className="mr-2 h-4 w-4" />
                  {`Our Expertise`}
                </Link>
                <Link
                  to=""
                  onClick={() => {
                    const expertiseSection =
                      document.getElementById("portfolio-section");
                    if (expertiseSection) {
                      const offsetTop = expertiseSection.offsetTop - 30; // Adjust this value to scroll just above the top
                      window.scrollTo({
                        top: offsetTop,
                        behavior: "smooth",
                      });
                    }
                    setShowSheet(false);
                  }}
                  className={cn(
                    buttonVariants({
                      variant: "ghost",
                      size: "sm",
                    }),

                    "dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white",
                    "justify-start capitalize"
                  )}
                >
                  <ChevronsRight className="mr-2 h-4 w-4" />
                  {`Our Portfolio`}
                </Link>
                <Link
                  to=""
                  onClick={() => {
                    const expertiseSection =
                      document.getElementById("contact-us");
                    if (expertiseSection) {
                      const offsetTop = expertiseSection.offsetTop - 30; // Adjust this value to scroll just above the top
                      window.scrollTo({
                        top: offsetTop,
                        behavior: "smooth",
                      });
                    }
                    setShowSheet(false);
                  }}
                  className={cn(
                    buttonVariants({
                      variant: "ghost",
                      size: "sm",
                    }),

                    "dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white",
                    "justify-start capitalize"
                  )}
                >
                  <ChevronsRight className="mr-2 h-4 w-4" />
                  {`Contact us`}
                </Link>
              </div>
            </div>
          </div>

          <div className=" pt-4 mt-1 border-t border-gray-200 mb-1">
            <div className=" ">
              <div className="flex flex-col items-start justify-start ">
                <Link
                  to=""
                  onClick={handleOrderPackage}
                  className={cn(
                    buttonVariants({
                      variant: "default",
                      size: "sm",
                    }),

                    "bg-[#3EB555] hover:bg-[#359C4A]",
                    "justify-start capitalize"
                  )}
                >
                  <ChevronsRight className="mr-2 h-4 w-4" />
                  {`Order Now`}
                </Link>
              </div>
              <p className="mt-8 font-semibold text-sm text-gray-900">
                Connect with us
              </p>

              <div className="mt-1 flex items-center space-x-3">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/powerring_ng"
                >
                  <img src="/facebook.png" alt="twitter" className="h-8 w-8" />
                </a>

                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/powerring_ng"
                >
                  <img src="/instagram.png" alt="twitter" className="h-8 w-8" />
                </a>

                <a
                  target="_blank"
                  href="https://wa.me/2348137369642?text=Hello%20there!%20I%20would%20like%20to%20make%20an%20inquiry."
                  rel="noopener noreferrer"
                >
                  <img src="/whatsapp.png" alt="whatsapp" className="h-8 w-8" />
                </a>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <h2 className="text-lg text-gray-900 font-medium">
              {installerName}
            </h2>
            <div className="aspect-w-16 aspect-h-9 rounded-[8px] overflow-hidden h-[100px] sm:h-full w-[150px] relative">
              <img
                className="w-full h-full object-contain rounded-[8px]"
                src={image}
                alt="installer thumbnail"
              />
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
}
