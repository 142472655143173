import React from "react";
import { Button } from "../../../components/ui/button";
import { ScrollArea } from "../../../components/ui/scroll-area";
import { Separator } from "../../../components/ui/separator";
import { ScrollAreaForHowItWorks } from "../../../components/ui/scroll-area-works";
import { IdState, useIdStore } from "../../../store/customer-store";
import { useNavigate, useParams } from "react-router-dom";

const steps = [
  {
    title: "Initiate Product Demand",
    description: "The user answers a set of user-friendly questions",
  },
  {
    title: "Receive recommendations",
    description: " The system recommends solar energy packages to users",
  },
  {
    title: " Select a Solar Solution",
    description: " User selects preferred package",
  },
  {
    title: "Choose an Installer",
    description: "User explores from a list verified installation companies",
  },
  {
    title: "Explore Financing Options",
    description:
      "User pays for the solar project or chooses from a range of financing options on the platform",
  },
  {
    title: " Project Deployment",
  },
];
const HowItWork = () => {
  const navigate = useNavigate();
  const setInstallerId = useIdStore((state: IdState) => state.setInstallerId);
  const packageId = useIdStore((state: IdState) => state.readPackageId());

  const { installerId } = useParams<{ installerId: string }>();

  const handleOrderPackage = () => {
    setInstallerId(installerId ?? "");

    if (packageId) {
      navigate("/select-payment-plan");
    } else {
      navigate("/order-a-system");
    }
  };
  return (
    <section className="py-20 overflow-hidden  dark:bg-gray-800">
      <div className="container px-4 mx-auto">
        <div className="max-w-5xl mx-auto">
          <div className="flex flex-wrap items-start -mx-5">
            <div className="w-full lg:w-1/2 px-5 mb-20 lg:mb-0">
              <div className="max-w-md">
                <span className="text-lg font-bold text-[#012B27] dark:text-blue-400">
                  Getting Started
                </span>
                <h2 className="mt-12 mb-10 text-5xl font-extrabold leading-tight text-gray-800 dark:text-gray-200">
                  Launch Your Solar Journey with Ease
                </h2>
                <p className="mb-16 text-[0.9rem] text-[#555555]">
                  A simple 6-step process to get you started and power up your
                  space
                </p>

                <Button
                  onClick={handleOrderPackage}
                  className="bg-[#3EB555] hover:bg-[#359C4A]"
                >
                  Get Started
                </Button>
              </div>
            </div>
            <div className="w-full lg:w-1/2 px-5">
              <ScrollAreaForHowItWorks className="h-full md:h-[30rem] w-full ">
                <div>
                  {steps.map((step, idx) => (
                    <>
                      <li key={idx} className="flex pb-6 mb-8 ">
                        <div className="mr-8">
                          <span className="flex justify-center items-center w-14 h-14 bg-[#3EB555] dark:bg-blue-600/30 text-lg font-bold rounded-full text-[#012B27] dark:text-blue-300">
                            {idx + 1}
                          </span>
                        </div>
                        <div className="max-w-xs">
                          <h3 className="mb-2  font-bold text-gray-700 dark:text-gray-300">
                            {step.title}
                          </h3>
                          <p className="text-[0.9rem] text-gray-500 dark:text-gray-400">
                            {step.description}
                          </p>
                        </div>
                      </li>
                      <Separator className="my-2" />
                    </>
                  ))}
                </div>
              </ScrollAreaForHowItWorks>

              {/* <ul>
                <li className="flex pb-6 mb-8 border-b border-gray-200 dark:border-gray-700">
                  <div className="mr-8">
                    <span className="flex justify-center items-center w-14 h-14 bg-[#3EB555] dark:bg-blue-600/30 text-lg font-bold rounded-full text-[#012B27] dark:text-blue-300">
                      1
                    </span>
                  </div>
                  <div className="max-w-xs">
                    <h3 className="mb-2 text-lg font-bold text-gray-700 dark:text-gray-300">
                      Initiate Product Demand
                    </h3>
                    <p className="text-lg text-gray-500 dark:text-gray-400">
                      The user answers a set of user-friendly questions
                    </p>
                  </div>
                </li>
                <li className="flex pb-6 mb-8 border-b border-gray-200 dark:border-gray-700">
                  <div className="mr-8">
                    <span className="flex justify-center items-center w-14 h-14 bg-[#3EB555] dark:bg-blue-600/30 text-lg font-bold rounded-full text-[#012B27] dark:text-blue-300">
                      2
                    </span>
                  </div>
                  <div className="max-w-xs">
                    <h3 className="mb-2 text-lg font-bold text-gray-700 dark:text-gray-300">
                      Receive recommendations
                    </h3>
                    <p className="text-lg text-gray-500 dark:text-gray-400">
                      The system recommends solar energy packages to users
                    </p>
                  </div>
                </li>
                <li className="flex pb-6 border-b border-gray-200 dark:border-gray-700">
                  <div className="mr-8">
                    <span className="flex justify-center items-center w-14 h-14 bg-[#3EB555] dark:bg-blue-600/30 text-lg font-bold rounded-full text-[#012B27] dark:text-blue-300">
                      3
                    </span>
                  </div>
                  <div className="max-w-xs">
                    <h3 className="mb-2 text-lg font-bold text-gray-700 dark:text-gray-300">
                      Select a Solar Solution
                    </h3>
                    <p className="text-lg text-gray-500 dark:text-gray-400">
                      User selects preferred package
                    </p>
                  </div>
                </li>

                <li className="flex pt-8 pb-6 border-b border-gray-200 dark:border-gray-700">
                  <div className="mr-8">
                    <span className="flex justify-center items-center w-14 h-14 bg-[#3EB555] dark:bg-blue-600/30 text-lg font-bold rounded-full text-[#012B27] dark:text-blue-300">
                      4
                    </span>
                  </div>
                  <div className="max-w-xs">
                    <h3 className="mb-2 text-lg font-bold text-gray-700 dark:text-gray-300">
                      Choose an Installer
                    </h3>
                    <p className="text-lg text-gray-500 dark:text-gray-400">
                      User explores from a list verified installation companies
                    </p>
                  </div>
                </li>
                <li className="flex pt-8 pb-6 border-b border-gray-200 dark:border-gray-700">
                  <div className="mr-8">
                    <span className="flex justify-center items-center w-14 h-14 bg-[#3EB555] dark:bg-blue-600/30 text-lg font-bold rounded-full text-[#012B27] dark:text-blue-300">
                      5
                    </span>
                  </div>
                  <div className="max-w-xs">
                    <h3 className="mb-2 text-lg font-bold text-gray-700 dark:text-gray-300">
                      Explore Financing Options
                    </h3>
                    <p className="text-lg text-gray-500 dark:text-gray-400">
                      User pays for the solar project or chooses from a range of
                      financing options on the platform
                    </p>
                  </div>
                </li>
                <li className="flex pt-8 pb-6 border-b border-gray-200 dark:border-gray-700">
                  <div className="mr-8">
                    <span className="flex justify-center items-center w-14 h-14 bg-[#3EB555] dark:bg-blue-600/30 text-lg font-bold rounded-full text-[#012B27] dark:text-blue-300">
                      6
                    </span>
                  </div>
                  <div className="max-w-xs">
                    <h3 className="mb-2 text-lg font-bold text-gray-700 dark:text-gray-300">
                      Project Deployment
                    </h3>
                  </div>
                </li>

               
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWork;
