import React, { forwardRef, useState } from "react";
import { z } from "zod";

import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { useGetPackageDetailsQuery } from "../../../store/ordersApiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import imagesUrl from "../../../assets/images/imagesUrl";
import { formatPrice } from "../../../lib/utils";
import { ScrollArea } from "../../../components/ui/scroll-area";
import { IdState, useIdStore } from "../../../store/customer-store";
import { Button } from "../../../components/ui/button";
import { useNavigate } from "react-router-dom";

type SubcategoryProps = {
  children: React.ReactNode;
  uniq_id: string;
};

export const PackageDetails = forwardRef<HTMLDivElement, SubcategoryProps>(
  ({ children, uniq_id }, ref) => {
    const [open, setOpen] = useState(false);
    const installerId = useIdStore((state) => state.readInstallerId());
    const setPackageId = useIdStore((state: IdState) => state.setPackageId);
    const navigate = useNavigate();

    // let loadingToastId: string | number;
    const { data, isLoading, refetch } = useGetPackageDetailsQuery(
      uniq_id ? { uniq_id: uniq_id } : skipToken
    );

    const packageDetails = data?.data;

    // console.log(packageDetails, "package details");
    const description = packageDetails?.package_description?.replace(
      /style="[^"]*"/g,
      ""
    );

    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>{children}</DialogTrigger>
        <DialogContent className="sm:max-w-[625px] bg-[#233C39] border-[#233C39]">
          <DialogHeader>
            <DialogTitle className="text-white text-[30px] leading-[36px] font-normal">
              Package Details
            </DialogTitle>
          </DialogHeader>

          <ScrollArea className="h-[28rem] md:h-[32rem] w-full ">
            <div className="w-full pr-2.5">
              <div className="bg-white/10 rounded-lg h-[148px] space-x-3 p-2 grid grid-cols-3 item-center">
                <div className=" h-full  overflow-hidden rounded-lg">
                  <img
                    className="bg-contain w-full h-[100%] aspect-auto"
                    src={packageDetails?.img_src_lg}
                    // width="100%"
                    // height="100%"
                    alt="inverter rep"
                    aria-hidden="true"
                  />
                </div>
                <div className="col-span-2">
                  <div>
                    <p className="text-white font-500 text-[19px]">
                      {packageDetails?.package_name}
                    </p>
                    <p className="text-[#CCCCCC] text-[15px] font-normal">
                      House items
                    </p>
                  </div>
                  <div>
                    <p className="text-[35px] text-white">
                      {formatPrice(packageDetails?.total_item_sales_price ?? 0)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-col space-y-1.5 border-[#cccccc] border-b py-2 border-dashed">
                <div className="w-full flex items-center justify-between">
                  <p className="text-[#B1B1B1]">
                    {packageDetails?.battery.title}
                  </p>
                  <p className="text-white">{`${packageDetails?.battery.total}`}</p>
                </div>
                <div className="w-full flex items-center justify-between">
                  <p className="text-[#B1B1B1]">
                    {packageDetails?.panel.title}
                  </p>
                  <p className="text-white">{`${packageDetails?.panel.total}`}</p>
                </div>
                <div className="w-full flex items-center justify-between">
                  <p className="text-[#B1B1B1]">
                    {packageDetails?.inverter.title}
                  </p>
                  <p className="text-white">{`${packageDetails?.inverter.total}`}</p>
                </div>
                <div className="w-full flex items-center justify-between">
                  <p className="text-[#B1B1B1]">
                    {packageDetails?.charge_controller.title}
                  </p>
                  <p className="text-white">{`${packageDetails?.charge_controller.total}`}</p>
                </div>
              </div>

              <div className="w-full border-b border-[#cccccc] border-dashed py-3 ">
                <div>
                  <p className="text-[19px] font-500 text-white">
                    Package items
                  </p>
                </div>
                <div className="flex items-center justify-between w-full">
                  <p className="text-[#B1B1B1] text-[14px]">Item</p>
                  <p className="text-[#B1B1B1] text-[14px]">Quantity</p>
                </div>

                <div className="flex items-center flex-col space-y-1.5 w-full">
                  {packageDetails?.package_items?.map((item, idx) => {
                    // Determine the image source based on the item type
                    const getImageSrc = () => {
                      switch (item.item_type_name.toLowerCase()) {
                        case "battery":
                          return imagesUrl.Battery;
                        case "inverter":
                          return imagesUrl.Inverter;
                        case "panel":
                          return imagesUrl.Panel;
                        case "charge_controller":
                          return imagesUrl.ChargeController;
                        default:
                          return imagesUrl.Toolbox;
                      }
                    };

                    return (
                      <div
                        key={idx}
                        className="w-full flex items-center justify-between"
                      >
                        <div className="flex items-center space-x-1">
                          <img
                            src={getImageSrc()}
                            width="32px"
                            height="32px"
                            alt={item.item_type_name}
                          />
                          <p className="text-[#B1B1B1]">{item.item_name}</p>
                        </div>
                        <p className="text-white">{item.qty}</p>
                      </div>
                    );
                  })}
                </div>

                {/* <div className="flex items-center flex-col space-y-1.5 w-full">
                  {packageDetails?.package_items?.map((item, idx) => (
                    <div
                      key={idx}
                      className="w-full flex items-center justify-between"
                    >
                      <div className="flex items-center space-x-1">
                        <img
                          src={imagesUrl.Battery}
                          width="32px"
                          height="32px"
                          alt="design"
                        />
                        <p className="text-[#B1B1B1]">{item.item_name}</p>
                      </div>
                      <p className="text-white">{item.qty}</p>
                    </div>
                  ))}
                </div> */}
              </div>
              <div className="w-full border-[#cccccc]  border-dashed py-3 ">
                <div>
                  <p className="text-[19px] font-500 text-white">
                    Why Choose this Package?
                  </p>
                </div>
                <div className="flex bg-[#cccccc]  px-4 overflow-hidden rounded-lg mt-3 items-center justify-between w-full">
                  <p
                    className=" text-sm"
                    dangerouslySetInnerHTML={{
                      __html: packageDetails?.package_description || "",
                    }}
                  ></p>
                </div>

                <div className="flex w-full items-center justify-center mt-3">
                  <Button
                    onClick={() => {
                      if (installerId) {
                        navigate("/select-payment-plan");
                      } else {
                        navigate("/search/installers");
                      }
                      setPackageId(uniq_id);
                    }}
                    className="text-black bg-[#3EB555] hover:bg-[#359C4A] w-full"
                  >
                    Order Now
                  </Button>
                </div>
              </div>
            </div>
          </ScrollArea>
        </DialogContent>
      </Dialog>
    );
  }
);

PackageDetails.displayName = "PackageDetails";

// onboard wallet
