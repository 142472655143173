import { HardHat } from "lucide-react";
import { expertiseImagesUrl } from "../../../../assets/images/imagesUrl";
import { useMemo } from "react";
import MaxWidthWrapper from "./max-width-wrapper";

type ExpertiseProps = {
  expertise: {
    expertise_id: string;
    expertise_name: string;
    expertise_details: string;
  }[];
};

const OurExpertise = (props: ExpertiseProps) => {
  const shuffledImages = useMemo(() => {
    const shuffled = [...expertiseImagesUrl].sort(() => Math.random() - 0.5);
    return shuffled.slice(0, props.expertise.length);
  }, [props.expertise.length]);

  return (
    <section
      id="expertise-section"
      className="mt-20 py-8 sm:py-16 bg-[#f7f7f7] rounded-lg "
    >
      <MaxWidthWrapper>
        <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Our Expertise
        </p>
        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 justify-center gap-10 items-center dark:bg-gray-800 w-full">
          {props?.expertise.map((expertise, index) => (
            <div className="relative dark:text-white h-[25rem] group">
              <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-[#012B27] rounded-lg dark:bg-gray-200"></span>
              <div className="h-[25rem] relative py-4 px-3 bg-white overflow-hidden dark:bg-gray-800 border-2 border-[#012B27] dark:border-gray-300 rounded-lg hover:scale-105 transition duration-500">
                <div className="flex flex-col">
                  <div className="flex items-center space-x-1">
                    <HardHat />

                    <h3 className="my-2 ml-3 font-bold text-gray-800 dark:text-white">
                      {expertise.expertise_name}
                    </h3>
                  </div>
                </div>
                <p className="text-[0.9rem] text-[#555555]">
                  {expertise.expertise_details}
                </p>

                <img
                  src={shuffledImages[index]}
                  alt={expertise.expertise_name}
                  className="rounded-tl-2xl border-4 border-[#1e1e1e] absolute right-0 bottom-0 w-[80%] h-[55%] object-cover translate-x-[5%] translate-y-[5%] overflow-hidden"
                />
              </div>
            </div>
          ))}
        </div>
      </MaxWidthWrapper>
    </section>
  );
};

export default OurExpertise;
