import React, { useState } from "react";
import MaxWidthWrapper from "../../../components/max-width-wrapper";

import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import {
  Check,
  ChevronRight,
  ChevronsUpDown,
  Loader2,
  Search,
} from "lucide-react";
import { ClipLoader, BeatLoader } from "react-spinners";

import {
  FinInstitution,
  useGetFinancialInstitutionsQuery,
  useSubmitBankMutation,
} from "../../../store/ordersApiSlice";
import { IdState, useIdStore } from "../../../store/customer-store";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import imagesUrl from "../../../assets/images/imagesUrl";
import { ScrollArea } from "../../../components/ui/scroll-area";

const SelectBankForm = () => {
  const [open, setOpen] = useState(true);
  const [selectedBankId, setSelectedBankId] = useState("");
  const [selectedBankName, setSelectedBankName] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // Search term state

  const navigate = useNavigate();

  const { data, isLoading, refetch } = useGetFinancialInstitutionsQuery();
  const finInstitutions = data?.data || [];

  const [submitBank, { isLoading: submitBankLoading }] =
    useSubmitBankMutation();

  const orderId = useIdStore((state: IdState) => state.readOrderId());
  const removeInstallerId = useIdStore((state) => state.removeInstallerId);
  const removePackageId = useIdStore((state) => state.removePackageId);
  const removeOrderId = useIdStore((state) => state.removeOrderId);
  const removeCustomerName = useIdStore((state) => state.removeCustomerName);
  const removeStateId = useIdStore((state) => state.removeStateId);
  const removeStateName = useIdStore((state) => state.removeStateName);
  const removeLgaId = useIdStore((state) => state.removeLgaId);
  const removeCustomerPhone = useIdStore((state) => state.removeCustomerPhone);
  const removeCustomerEmail = useIdStore((state) => state.removeCustomerEmail);

  async function onSubmit() {
    if (!selectedBankId) {
      toast.error("Financial institution is required");
    } else if (!orderId) {
      toast.error("You do not have an existing order");
    } else {
      toast.info("Setting preferred bank institution");

      try {
        const bank = await submitBank({
          financial_institution_uniq_id: selectedBankId,
          order_id: orderId,
        }).unwrap();

        toast.success("Preferred bank set successfully");

        console.log("Bank submitted successfully:", bank);
        navigate(`/order-complete`);
      } catch (error) {
        console.error("Error submitting bank:", error);
      }

      removeInstallerId();
      removeOrderId();
      removeCustomerName();
      removeStateId();
      removeStateName();
      removeLgaId();
      removeCustomerPhone();
      removeCustomerEmail();
      removePackageId();
    }
  }

  const handleSelectBank = (bankId: string, bankName: string) => {
    setSelectedBankId(bankId);
    setSelectedBankName(bankName);
    setSearchTerm("");
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setSelectedBankName("");
  };

  const filteredFinInstitutions = finInstitutions.filter((institution) =>
    institution.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <MaxWidthWrapper>
      <div className="w-full   items-center justify-center">
        <div className="w-full md:w-[50%] mx-auto flex flex-col space-y-10">
          <div className="flex flex-col items-center justify-center space-y-2">
            <h1 className="text-[32px] font-bold text-white">
              Financial Institution
            </h1>

            <p className="text-[16px] font-normal text-white">
              Select a financial institution for your payment plan
            </p>
          </div>
          <div className="w-full sm:w-[80%] mx-auto">
            <form className="flex flex-col space-y-3 items-start w-full">
              <div className="w-full flex items-start flex-col">
                <Input
                  className="w-full !focus:outline-none !outline-none text-white bg-[#DEEDCC33] border-transparent"
                  type="text"
                  value={selectedBankName || searchTerm} // Display selected bank name if available
                  onChange={handleSearchChange}
                  placeholder="Search financial institution"
                  endContent={<Search color="#ffffff" className="w-4 h-4" />}
                />
              </div>

              <div className=" w-full">
                <ScrollArea className="max-h-72 w-full">
                  <div className="">
                    {isLoading ? (
                      <BeatLoader color="#3EB555" loading size={10} />
                    ) : filteredFinInstitutions.length > 0 ? (
                      filteredFinInstitutions.map((item, idx) => (
                        <div
                          onClick={() =>
                            handleSelectBank(item.uniq_id, item.name)
                          }
                          key={idx}
                          className={`mb-1 cursor-pointer border-2 hover:border-2 border-transparent hover:border-[#3EB555] text-sm bg-[#DEEDCC33] p-1 rounded-lg ${
                            selectedBankId === item.uniq_id
                              ? "border-2 !border-[#3EB555]"
                              : ""
                          }`}
                        >
                          <div className="flex w-full items-center space-x-1.5 ">
                            <img
                              src={item.img ?? imagesUrl.Bank}
                              className="rounded-md h-[50px] w-[50px] object-contain"
                              alt="bank"
                            />

                            <div className=" flex items-start space-y-1 flex-col w-full">
                              <p className="text-[white] text-[19px] font-medium">
                                {item.name}
                              </p>
                              <p className=" text-[16px] font-medium">
                                <span className="text-white">{`Interest rate:`}</span>{" "}
                                <span className="text-[#3EB555]">
                                  {`${item.interest_rate}`}
                                </span>
                              </p>
                            </div>

                            <div className="flex items-center justify-center">
                              <ChevronRight
                                color="#3EB555"
                                className="w-4 h-4"
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-sm text-[#cccccc]">
                        No Financial institution found
                      </p>
                    )}
                  </div>
                </ScrollArea>
              </div>

              <div className="w-full flex items-center justify-center">
                <Button
                  // disabled={!form.formState.isDirty}
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmit();
                  }}
                  type="submit"
                  className="w-full font-bold text-black bg-[#3EB555] hover:bg-[#359C4A]"
                >
                  {submitBankLoading ? (
                    <Loader2 className="h-4 w-4 animate-spin text-white" />
                  ) : (
                    "Proceed"
                  )}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </MaxWidthWrapper>
  );
};

export default SelectBankForm;
