import { cn } from "../../../../lib/utils";

interface FooterListProps {
  children: React.ReactNode;
  className?: string;
}

const FooterList: React.FC<FooterListProps> = ({ children, className }) => {
  return (
    <div className={cn("w-full   flex flex-col gap-2 ", className)}>
      {children}
    </div>
  );
};

export default FooterList;
