import React from "react";
import MaxWidthWrapper from "../../../components/max-width-wrapper";
import imagesUrl from "../../../assets/images/imagesUrl";
import { Button } from "../../../components/ui/button";
import { useNavigate } from "react-router-dom";
import { IdState, useIdStore } from "../../../store/customer-store";

const ThankYou = () => {
  const navigate = useNavigate();
  const removePaymentTypeId = useIdStore(
    (state: IdState) => state.removePaymentTypeId
  );

  return (
    <MaxWidthWrapper>
      <div className="w-full mt-4 md:mt-10 ">
        <div className="h-full flex flex-col items-center justify-center space-y-12">
          <div className="flex flex-col items-center justify-center space-y-6">
            <div className="flex flex-col items-center justify-center space-y-3">
              <img
                src={imagesUrl.ThankYouCheck}
                height={"102px"}
                width="102px"
                alt="check"
              />
              <p className="text-white font-bold text-[32px]">
                Thank you for your Order
              </p>
            </div>

            <div>
              <p className="text-white text-[20] font-medium max-w-prose">
                A Customer representative agent will get back to you as soon as
                possible.
              </p>
            </div>
          </div>

          <div className=" w-full flex items-center justify-center">
            <Button
              onClick={() => {
                navigate("/");
                removePaymentTypeId();
              }}
              className=" font-bold text-black bg-[#3EB555] hover:bg-[#359C4A]"
            >
              Go back home
            </Button>
          </div>
        </div>
      </div>
    </MaxWidthWrapper>
  );
};

export default ThankYou;
