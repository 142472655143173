import React, { useEffect, useState } from "react";
import {
  Check,
  ChevronRight,
  ChevronsUpDown,
  Eye,
  EyeOff,
  Loader2,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { IdState, useIdStore } from "../../../store/customer-store";
import { CreateAccountSchema } from "../../../types/create-account-schema";
import MaxWidthWrapper from "../../../components/max-width-wrapper";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { useCreateAccountMutation } from "../../../store/authApiSlice";
import { toast } from "sonner";
import { useToggleState } from "../../../hooks/useToggleState";

const CreateAccountForm = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { state: showPassword, toggle: togglePasswordShow } = useToggleState();
  const { state: showConfirmPassword, toggle: toggleConfirmPasswordShow } =
    useToggleState();

  const setOrderId = useIdStore((state: IdState) => state.setOrderId);
  const setCustomerEmail = useIdStore(
    (state: IdState) => state.setCustomerEmail
  );
  const setCustomerPhone = useIdStore(
    (state: IdState) => state.setCustomerPhone
  );

  const paymentTypeId = useIdStore((state) => state.readPaymentTypeId());
  const customerName = useIdStore((state) => state.readCustomerName());
  const lgaId = useIdStore((state) => state.readLgaId());
  const installerId = useIdStore((state) => state.readInstallerId());
  const packageId = useIdStore((state) => state.readPackageId());

  const [createAccount, { isLoading }] = useCreateAccountMutation();

  const form = useForm<z.infer<typeof CreateAccountSchema>>({
    resolver: zodResolver(CreateAccountSchema),
    defaultValues: {
      email: "",
      phone: "",
      password: "",
      confirm_password: "",
    },
  });

  const formValues = form.watch();

  useEffect(() => {
    if (formValues.email) {
      setCustomerEmail(formValues.email);
    }
  }, [formValues.email]);

  useEffect(() => {
    if (formValues.phone) {
      setCustomerPhone(formValues.phone);
    }
  }, [formValues.phone]);

  async function onSubmit(values: z.infer<typeof CreateAccountSchema>) {
    if (!packageId) {
      toast.error("You have not selected a package");
    } else if (!installerId) {
      toast.error("You have not selected an installer");
    } else if (!customerName) {
      toast.error("You have not set a customer name");
    } else if (!paymentTypeId) {
      toast.error("You have not set a payment type");
    } else if (!lgaId) {
      toast.error("You have not set a LGA");
    } else {
      toast.info("Processing order. Please wait...");

      try {
        const order = await createAccount({
          // order_id: orderId,
          phone: values.phone,
          email: values.email,
          password: values.password,

          package_uniq_id: packageId,
          installer_uniq_id: installerId,
          lga_id: lgaId,
          name: customerName,
          payment_type: paymentTypeId,
        }).unwrap();

        if (order?.status === true) {
          toast.success("Order placed successfully");
        } else {
          toast.error(order?.message);
        }

        console.log("User created successfully:", order);

        setOrderId(order?.order_id);
        if (paymentTypeId === "1") {
          navigate(`/invoice-one-time/?order_id=${order?.order_id}`);
        } else {
          navigate(`/select-bank`);
        }
      } catch (error) {
        console.error("Error creating user:", error);
      }
    }
  }

  return (
    <MaxWidthWrapper>
      <div className="w-full   items-center justify-center">
        <div className="w-full md:w-[50%] mx-auto flex flex-col space-y-10">
          <h1 className="text-[32px] font-bold text-white">
            Create an account
          </h1>
          <div className="w-full">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex flex-col space-y-3 items-start w-full"
              >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="w-full flex items-start flex-col">
                      <FormLabel className="text-white text-base">
                        Email{" "}
                      </FormLabel>

                      <FormControl>
                        <Input
                          {...field}
                          className="w-full  bg-[#DEEDCC]"
                          type="text"
                          // onChange={() => setCustomerName(field.value)}
                          // value={installer?.name ?? ""}
                          // disabled={true}

                          placeholder="Enter email"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem className="w-full flex items-start flex-col">
                      <FormLabel className="text-white text-base">
                        Phone Number{" "}
                      </FormLabel>

                      <FormControl>
                        <Input
                          {...field}
                          className="w-full  bg-[#DEEDCC]"
                          type="text"
                          // onChange={() => setCustomerName(field.value)}
                          // value={installer?.name ?? ""}
                          // disabled={true}

                          placeholder="Enter Phone number"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem className="w-full flex items-start flex-col">
                      <FormLabel className="text-white text-base">
                        Password{" "}
                      </FormLabel>

                      <FormControl>
                        <Input
                          {...field}
                          className="w-full  bg-[#DEEDCC]"
                          placeholder="Password"
                          type={showPassword ? "text" : "password"}
                          autoComplete="current-password"
                          endContent={
                            <span onClick={togglePasswordShow}>
                              {showPassword ? (
                                <EyeOff className="w-4 h-4" />
                              ) : (
                                <Eye className="w-4 h-4" />
                              )}
                            </span>
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="confirm_password"
                  render={({ field }) => (
                    <FormItem className="w-full flex items-start flex-col">
                      <FormLabel className="text-white text-base">
                        Confirm Passowrd{" "}
                      </FormLabel>

                      <FormControl>
                        <Input
                          {...field}
                          className="w-full  bg-[#DEEDCC]"
                          placeholder="Confirm Password"
                          type={showConfirmPassword ? "text" : "password"}
                          autoComplete="current-password"
                          endContent={
                            <span onClick={toggleConfirmPasswordShow}>
                              {showConfirmPassword ? (
                                <EyeOff className="w-4 h-4" />
                              ) : (
                                <Eye className="w-4 h-4" />
                              )}
                            </span>
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="w-full flex items-center justify-center">
                  <Button
                    disabled={!form.formState.isDirty}
                    type="submit"
                    className="w-full font-bold text-black bg-[#3EB555] hover:bg-[#359C4A]"
                  >
                    {isLoading ? (
                      <Loader2 className="h-4 w-4 animate-spin text-white" />
                    ) : (
                      "Proceed"
                    )}
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </div>
      </div>
    </MaxWidthWrapper>
  );
};

export default CreateAccountForm;
