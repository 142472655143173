import { apiSlice } from "./apiSlice";

const INSTALLER_URL = "installer";

export type Installer = {
  _id: string;
  url: string;
  name: string;
  about: string;
  username: string;
  uniq_id: string;
  state_name: string;
  response_time_name: string;
  image: string;
  installations: string;
  address: string;
  phone: string;
  email: string;
  approved: string;
};

export type InstallersResponse = {
  data: Installer[];
  message: string;
  status: boolean;
};

type InstallerDetailDTO = {
  id: string | null;
  name: string | null;
  state: string;
  state_name: string;
  response_time: string;
  response_time_name: string;
  business_type: string | null;
  domain_type: string | null;
  url: string;
  address: string | null;
  phone: string | null;
  email: string | null;
  working_days: string | null;
  whatsapp: string | null;
  instagram: string | null;
  facebook: string | null;
  twitter: string | null;
  linkedin: string | null;
  youtube: string | null;
  about: string | null;
  expertise: {
    id: string;
    expertise_id: string;
    expertise_name: string;
    expertise_details: string;
  }[];
  image: string;
  installations: string | null;
};

export type InstallerResponse = {
  data: InstallerDetailDTO;
  message: string;
  status: boolean;
};

export const installersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInstallers: builder.query<InstallersResponse, void>({
      query: () => ({
        url: `${INSTALLER_URL}/installer-lists/`,
        method: "GET",
      }),
    }),

    getInstaller: builder.query<InstallerResponse, { uniq_id: string }>({
      query: ({ uniq_id }) => ({
        url: `${INSTALLER_URL}/installer-profile/?uniq_id=${uniq_id}`,
        method: "GET",
      }),
    }),

    searchInstallers: builder.mutation({
      query: (data) => ({
        url: `${INSTALLER_URL}/process`,
        method: "POST",
        body: {
          action: "search_installer",
          state_id: data.state_id,
          search: data.search,
          limit: data.limit,
        },
      }),
    }),
  }),
});

export const {
  useGetInstallerQuery,
  useGetInstallersQuery,
  useSearchInstallersMutation,
} = installersApiSlice;
