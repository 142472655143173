"use client";

import React from "react";

import FooterList from "./footer-list";
import { Link } from "react-router-dom";
import imagesUrl from "../../../../assets/images/imagesUrl";
import MaxWidthWrapper from "./max-width-wrapper";
import { Phone } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { IdState, useIdStore } from "../../../../store/customer-store";

type FooterProps = {
  facebook: string;
  instagram: string;
  youtube: string;
  linkedin: string;
  twitter: string;
  whatsapp: string;
  about: string;
  installerName: string;
  phone: string;
  image: string;
};

const Footer = (props: FooterProps) => {
  const navigate = useNavigate();
  const setInstallerId = useIdStore((state: IdState) => state.setInstallerId);
  const packageId = useIdStore((state: IdState) => state.readPackageId());

  const { installerId } = useParams<{ installerId: string }>();

  const handleOrderPackage = () => {
    setInstallerId(installerId ?? "");

    if (packageId) {
      navigate("/select-payment-plan");
    } else {
      navigate("/order-a-system");
    }
  };

  return (
    <div className=" bg-[#0a1100] relative text-slate-200 text-sm mt-16">
      <div className="absolute z-20 inset-0 bg-[#000000] opacity-70"></div>

      <MaxWidthWrapper className="z-30">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-y-4 lg:gap-y-0  py-3  gap-x-3 ">
          <FooterList>
            <div className=" rounded-[8px] overflow-hidden h-[80px]  w-[80px] relative">
              <img
                className="w-full h-full object-cover rounded-[8px]"
                src={props.image}
                alt="installer thumbnail"
              />
            </div>
          </FooterList>

          <FooterList>
            <h3 className="text-base font-bold ">Our Solution</h3>

            <Link
              onClick={() => {
                const expertiseSection =
                  document.getElementById("expertise-section");
                if (expertiseSection) {
                  const offsetTop = expertiseSection.offsetTop - 0; // Adjust this value to scroll just above the top
                  window.scrollTo({
                    top: offsetTop,
                    behavior: "smooth",
                  });
                }
              }}
              className="text-[#cccccc]"
              to=""
            >
              Our Expertise
            </Link>

            <Link onClick={handleOrderPackage} className="text-[#cccccc]" to="">
              Order
            </Link>
          </FooterList>

          <FooterList>
            <div className=" flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <div
                  className="text-[#cccccc] flex flex-col cursor-pointer "
                  onClick={() => navigate("/")}
                >
                  <em>Powered by</em>
                  <img
                    loading="eager"
                    src={imagesUrl.Logo}
                    alt="logo"
                    height={15}
                    width={123}
                  />
                </div>
              </div>
              <div className="flex items-center gap-1.5">
                <Phone className="w-4 h-4" />
                <span className="">0813 736 9642</span>
              </div>
            </div>
          </FooterList>
          <FooterList className="mr-auto">
            <p className="text-[#cccccc]">
              &copy; {new Date().getFullYear()} {`${props.installerName}`}
            </p>
          </FooterList>
        </div>
      </MaxWidthWrapper>
    </div>
  );
};

export default Footer;
