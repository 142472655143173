import React, { useState, useEffect } from "react";
import { Button } from "../../../components/ui/button";
import MaxWidthWrapper from "../../../components/max-width-wrapper";
import { Checkbox } from "../../../components/ui/checkbox";
import { useNavigate } from "react-router-dom";
import {
  Lga,
  Order,
  OrderItem,
  useGetLgasQuery,
  useGetStatesQuery,
} from "../../../store/ordersApiSlice";
import { useIdStore } from "../../../store/customer-store";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetInstallerQuery } from "../../../store/installersApiSlice";
import { formatPrice } from "../../../lib/utils";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../../../components/ui/form";
import { useForm } from "react-hook-form";
import { TermsSchema } from "../../../types/terms-schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Loader2 } from "lucide-react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const Invoice = ({ orderDetails }: { orderDetails: Order | undefined }) => {
  const navigate = useNavigate();
  console.log(orderDetails, "orderdetails");
  const form = useForm<z.infer<typeof TermsSchema>>({
    resolver: zodResolver(TermsSchema),
    defaultValues: {
      terms: false,
    },
  });

  const formValues = form.watch();

  const installerId = orderDetails?.installer_uniq_id;

  const {
    data: installerDetails,
    isLoading,
    refetch,
  } = useGetInstallerQuery(installerId ? { uniq_id: installerId } : skipToken);

  const installer = installerDetails?.data;

  const {
    data: stateData,
    isLoading: loadingState,
    refetch: refetchState,
  } = useGetStatesQuery();
  const stateId = useIdStore((state) => state.readStateId());

  const states = stateData?.data || [];

  const stateName = states.find((state) => state.state_id === stateId);
  const {
    data: lgaData,
    isLoading: isLoadingLga,
    refetch: refetchLga,
  } = useGetLgasQuery(stateId ? { state_id: stateId } : skipToken);

  const lgas = lgaData?.data || [];

  const lgaName = lgas.find(
    (lga: Lga) => lga.local_id === orderDetails?.lga_id
  );

  const customerName = useIdStore((state) => state.readCustomerName());

  const totalPrice =
    orderDetails?.order_items?.reduce((total, item: OrderItem) => {
      return total + (parseFloat(item.price) || 0) * (parseInt(item.qty) || 0);
    }, 0) || 0;

  useEffect(() => {
    console.log(formValues.terms);
  }, [formValues.terms]);

  const handleDownloadPDF = async () => {
    const element = document.getElementById("invoice-content");
    if (element) {
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0, 210, 0);
      pdf.save("invoice.pdf");
    }
  };

  return (
    <MaxWidthWrapper>
      <div className="w-full">
        <div>
          <h1 className="text-[32px] font-bold text-white">Invoice</h1>
          <div
            id="invoice-content"
            className="mt-6 md:mt-4 w-full sm:w-[60%] md:w-[70%] lg:w-[75%] mx-auto flex flex-col bg-[#DEEDCC33] rounded-3xl pt-[30px] pb-[10px] px-[16px] md:p-[35px] md:pb-[15px]"
          >
            <div>
              <div className="flex mb-2 flex-col space-y-1 items-center justify-center">
                {!installer?.image ? (
                  <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
                ) : (
                  <img
                    src={installer?.image}
                    alt="installer thumbnail"
                    className="rounded-full h-16 w-16"
                  />
                )}
                <div className="text-white flex items-center space-x-2">
                  <p className="text-base item-name">{installer?.name}</p>
                  {","}
                  <span className="text-base item-name">
                    {installer?.state_name}
                  </span>
                </div>
              </div>

              <div className=" pb-3 border-b border-dashed border-[#808080] w-full flex flex-col space-y-2">
                <div className="w-full flex items-center justify-between">
                  <p className="text-[#B1B1B1] text-sm font-[400] item-name">
                    Date & Time
                  </p>
                  <p className="font-medium text-white text-base text-right item-name">
                    {orderDetails?.date}
                  </p>
                </div>
                <div className="w-full flex items-center justify-between">
                  <p className="text-[#B1B1B1] text-sm font-[400] item-name">
                    Customer Name
                  </p>
                  <p className="font-medium text-white text-base text-right item-name">
                    {customerName}
                  </p>
                </div>
                <div className="w-full flex items-center justify-between">
                  <p className="text-[#B1B1B1] text-sm font-[400] item-name">
                    Location
                  </p>
                  <p className="font-medium text-white text-base text-right item-name">
                    {`${lgaName?.name}, ${stateName?.name}`}
                  </p>
                </div>
              </div>
              <div className="py-3 border-b border-dashed border-[#808080] w-full flex flex-col space-y-2">
                <div className="w-full flex items-center justify-between">
                  <p className="text-[#B1B1B1] text-sm font-[400] item-name">
                    Package
                  </p>
                  <p className="flex items-center space-x-1 font-medium text-white text-base text-right">
                    <span className="text-[#3EB555] item-name">Optimal:</span>
                    <span>{formatPrice(orderDetails?.amount ?? 0)}</span>
                  </p>
                </div>

                <div className="grid grid-cols-4 w-full">
                  <p className="text-sm text-[#B1B1B1] text-left item-name">
                    Item
                  </p>
                  <p className="text-sm text-[#B1B1B1] text-left item-name">
                    Quantity
                  </p>
                  <p className="text-sm text-[#B1B1B1] text-left item-name">
                    Rate
                  </p>
                  <p className="text-sm text-[#B1B1B1] text-left item-name">
                    Price
                  </p>
                </div>

                {orderDetails &&
                  orderDetails?.order_items.map((item, idx) => (
                    <div
                      key={idx}
                      className="item-name grid grid-cols-4 w-full"
                    >
                      <p className="text-white text-left item-name">
                        {item.name}
                      </p>
                      <p className="text-white text-left item-name">
                        {item.qty}
                      </p>
                      <p className="text-white text-left item-name">
                        {formatPrice(item.price ?? 0)}
                      </p>
                      <p className="text-white text-left item-name">
                        {formatPrice(
                          parseFloat(item.qty) * parseFloat(item.price)
                        )}
                      </p>
                    </div>
                  ))}
              </div>

              <div className="py-3 border-b border-dashed border-[#808080] w-full flex flex-col space-y-2">
                <div className="w-full flex items-center justify-between">
                  <p className="text-[#B1B1B1] text-sm font-[400] item-name">
                    Mode of Payment
                  </p>
                  <p className="item-name font-medium text-white text-base text-right">
                    {orderDetails?.payment_type === "1"
                      ? "One-time"
                      : "Installment"}
                  </p>
                </div>
              </div>
              <div className="pt-3  w-full flex flex-col space-y-2">
                <div className="w-full flex items-center justify-between">
                  <p className="text-[#B1B1B1] text-sm font-[400] item-name">
                    Total Amount in NGN
                  </p>
                  <p className="text-[20px] font-bold  text-white text-base text-right item-name">
                    {formatPrice(totalPrice || 0)}
                  </p>
                </div>
              </div>
              <div className="mb-auto mt-5">
                <p className="italic text-[14px] text-[#cccccc] item-name">
                  Powered By Power Ring
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col relative space-y-3 mt-4 w-full md:w-[50%] mx-auto">
            <Form {...form}>
              <form className="space-y-6">
                <FormField
                  control={form.control}
                  name="terms"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-start space-x-3 space-y-0 ">
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <div className="space-y-1 leading-none">
                        <FormLabel className="peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-white">
                          I accept the terms and agreement of PowerRing
                        </FormLabel>
                      </div>
                    </FormItem>
                  )}
                />
              </form>
            </Form>
            <div className="no-print w-full relative ">
              <div className="w-full">
                <Button
                  onClick={() => navigate("/make-payment")}
                  className="text-black w-full bg-[#3EB555] hover:bg-[#359C4A]"
                  disabled={!formValues.terms}
                >
                  Proceed
                </Button>
              </div>
              <div className="flex items-center justify-center">
                <Button
                  disabled={!formValues.terms}
                  variant={"link"}
                  onClick={() => window.print()}
                  className=" text-[#3EB555] hover:text-[#359C4A]"
                >
                  Download Invoice as PDF
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MaxWidthWrapper>
  );
};

export default Invoice;
