import React, { useEffect, useState } from "react";
import MaxWidthWrapper from "../../../components/max-width-wrapper";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { SearchInstallerSchema } from "../../../types/search-installer-schema";
import { SelectPlanSchema } from "../../../types/select-plan-schema";
import { toast } from "sonner";
import { useWindowWidth } from "@react-hook/window-size";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { Button } from "../../../components/ui/button";
import { Check, ChevronsUpDown, Loader2, Search } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../components/ui/command";
// import { states } from "../../../config";
import { cn, formatPrice } from "../../../lib/utils";
import { Input } from "../../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { RadioGroup, RadioGroupItem } from "../../../components/ui/radio-group";
import { Label } from "../../../components/ui/label";
import imagesUrl from "../../../assets/images/imagesUrl";
import { Switch } from "../../../components/ui/switch";
import {
  PackageDTO,
  RequestedPackageDto,
  RequestedPackageResponse,
  State,
  useGetPackagesMutation,
  useGetStatesQuery,
} from "../../../store/ordersApiSlice";
import { PackageDetails } from "./package-details";
import { IdState, useIdStore } from "../../../store/customer-store";

const PlanSelector = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const onlyWidth = useWindowWidth();
  const mobileWidth = onlyWidth < 768;

  const setId = useIdStore((state: IdState) => state.setStateId);
  const setStateName = useIdStore((state: IdState) => state.setStateName);
  const setLgaName = useIdStore((state: IdState) => state.setLgaName);

  const setPackageId = useIdStore((state: IdState) => state.setPackageId);
  const installerId = useIdStore((state) => state.readInstallerId());

  // const selectedState = useSelector(
  //   (state: RootState) => state?.state?.selectedState
  // );

  // const handleStateSelect = (stateId: string) => {
  //   dispatch(setState(stateId));
  // };

  const { data: stateData, isLoading: stateDataLoading } = useGetStatesQuery();
  const states = stateData?.data || [];

  const form = useForm<z.infer<typeof SelectPlanSchema>>({
    resolver: zodResolver(SelectPlanSchema),
    defaultValues: {
      state: "",
      installer: "",
      useNationalGrid: "",
      nepa_average_hour: "",
      buildingType: "",
      has_bedroom_with_tv: false,
      has_bedroom_without_tv: false,
      has_sitting_room: false,
      has_other_places: "",
      bedroom_with_tv_qty: "",
      bedroom_with_tv_avhours: "",
      sitting_room_qty: "",
      sitting_room_avhours: "",
      bedroom_without_tv_qty: "",
      bedroom_without_tv_avhours: "",
      entrance_room_qty: "",
      entrance_room_avhour: "",
      has_entrance_room: false,
      has_dining: false,
      dinning_qty: "",
      dinning_avhours: "",
      has_kitchen: false,
      kitchen_qty: "",
      kitchen_avhours: "",
      has_home_office: false,
      home_office_qty: "",
      home_office_avhours: "",
      has_garage: false,
      garage_qty: "",
      garage_avhours: "",
      has_heavy_appliance: "",
      has_fridge: "",
      has_table_top_fridge: false,
      table_top_fridge_qty: "",
      table_top_fridge_avhours: "",
      has_single_door_fridge: false,
      single_door_fridge_qty: "",
      single_door_fridge_avhours: "",
      has_double_door_fridge: false,
      double_door_fridge_qty: "",
      double_door_fridge_avhours: "",
      has_side_by_side_door_fridge: false,
      side_by_side_door_fridge_qty: "",
      side_by_side_door_fridge_avhours: "",
      has_single_door_deep_freezer: false,
      single_door_deep_freezer_qty: "",
      single_door_deep_freezer_avhours: "",
      has_double_door_deep_freezer: false,
      double_door_deep_freezer_qty: "",
      double_door_deep_freezer_avhours: "",
      has_inverter_ac: "",
      has_1hp_ac: false,
      ac_1hp_qty: "",
      ac_1hp_avhours: "",
      has_15hp_ac: false,
      ac_15hp_qty: "",
      ac_15hp_avhours: "",
      has_2hp_ac: false,
      ac_2hp_qty: "",
      ac_2hp_avhours: "",
      has_2_5hp_ac: false,
      ac_2_5hp_qty: "",
      ac_2_5hp_avhours: "",
      has_3hp_ac: false,
      ac_3hp_qty: "",
      ac_3hp_avhours: "",
      has_microwave: "",
      has_small_microwave: false,
      small_microwave_qty: "",
      small_microwave_avhours: "",
      has_big_microwave: false,
      big_microwave_qty: "",
      big_microwave_avhours: "",
      has_heater: "",
      has_heater_1000w: false,
      heater_1000w_qty: "",
      heater_1000w_avhours: "",
      has_heater_1500w: false,
      heater_1500w_qty: "",
      heater_1500w_avhours: "",
      has_heater_2000w: false,
      heater_2000w_qty: "",
      heater_2000w_avhours: "",
      has_pressing_iron: "",
      has_small_pressing_iron: false,
      small_pressing_iron_qty: "",
      small_pressing_iron_avhours: "",
      has_big_pressing_iron: false,
      big_pressing_iron_qty: "",
      big_pressing_iron_avhours: "",
      has_sumo_pump: "",
      has_sumo_1hp: false,
      sumo_1hp_qty: "",
      sumo_1hp_avhours: "",
      has_sumo_1_5hp: false,
      sumo_1_5hp_qty: "",
      sumo_1_5hp_avhours: "",
      has_sumo_2hp: false,
      sumo_2hp_qty: "",
      sumo_2hp_avhours: "",
      has_sumo_2_5hp: false,
      sumo_2_5hp_qty: "",
      sumo_2_5hp_avhours: "",
      has_sumo_3hp: false,
      sumo_3hp_qty: "",
      sumo_3hp_avhours: "",
    },
  });

  const [getPackages, { data, isLoading, error }] = useGetPackagesMutation();
  const [packageData, setPackageData] = useState<RequestedPackageDto | null>(
    null
  );
  const [responseStatus, setResponseStatus] = useState<number>(0);

  const formValues = form.watch();

  useEffect(() => {
    if (formValues.state) {
      console.log(formValues.state, "state selected");
      // Set the ID in the Zustand store using formValues.state directly
      setId(formValues.state);

      const selectedState = states.find(
        (state) => state.state_id === formValues.state
      );
      setStateName(selectedState?.name ?? "");
    }
  }, [formValues.state]);

  // const handleStateSelect = (stateId: string) => {
  //   console.log("Selected state ID:", stateId);
  //   dispatch(setState(stateId));
  // };

  const selectedState = form.watch("state");
  const selectedUseNationalGrid = form.watch("useNationalGrid");

  useEffect(() => {
    const params: PackageDTO = {
      state: formValues.state,
      nepa_average_hour: Number(formValues.nepa_average_hour) || 0,
      bedroom_with_tv_qty: Number(formValues.bedroom_with_tv_qty) || 0,
      bedroom_with_tv_avhours: Number(formValues.bedroom_with_tv_avhours) || 0,
      bedroom_without_tv_qty: Number(formValues.bedroom_without_tv_qty) || 0,
      bedroom_without_tv_avhours:
        Number(formValues.bedroom_without_tv_avhours) || 0,
      sitting_room_qty: Number(formValues.sitting_room_qty) || null,
      sitting_room_avhours: Number(formValues.sitting_room_avhours) || null,
      entrance_room_qty: Number(formValues.entrance_room_qty) || null,
      entrance_room_avhour: Number(formValues.entrance_room_avhour) || null,
      dinning_qty: Number(formValues.dinning_qty) || null,
      dinning_avhours: Number(formValues.dinning_avhours) || null,
      kitchen_qty: Number(formValues.kitchen_qty) || null,
      kitchen_avhours: Number(formValues.kitchen_avhours) || null,
      home_office_qty: Number(formValues.home_office_qty) || null,
      home_office_avhours: Number(formValues.home_office_avhours) || null,
      garage_qty: Number(formValues.garage_qty) || null,
      garage_avhours: Number(formValues.garage_avhours) || null,
      table_top_fridge_qty: Number(formValues.table_top_fridge_qty) || null,
      table_top_fridge_avhours:
        Number(formValues.table_top_fridge_avhours) || null,
      single_door_fridge_qty: Number(formValues.single_door_fridge_qty) || null,
      single_door_fridge_avhours:
        Number(formValues.single_door_fridge_avhours) || null,
      double_door_fridge_qty: Number(formValues.double_door_fridge_qty) || null,
      double_door_fridge_avhours:
        Number(formValues.double_door_fridge_avhours) || null,
      side_by_side_door_fridge_qty:
        Number(formValues.side_by_side_door_fridge_qty) || null,
      side_by_side_door_fridge_avhours:
        Number(formValues.side_by_side_door_fridge_avhours) || null,
      single_door_deep_freezer_qty:
        Number(formValues.single_door_deep_freezer_qty) || null,
      single_door_deep_freezer_avhours:
        Number(formValues.single_door_deep_freezer_avhours) || null,
      double_door_deep_freezer_qty:
        Number(formValues.double_door_deep_freezer_qty) || null,
      double_door_deep_freezer_avhours:
        Number(formValues.double_door_deep_freezer_avhours) || null,
      ac_1hp_qty: Number(formValues.ac_1hp_qty) || null,
      ac_1hp_avhours: Number(formValues.ac_1hp_avhours) || null,
      ac_15hp_qty: Number(formValues.ac_15hp_qty) || null,
      ac_15hp_avhours: Number(formValues.ac_15hp_avhours) || null,
      ac_2hp_qty: Number(formValues.ac_2hp_qty) || null,
      ac_2hp_avhours: Number(formValues.ac_2hp_avhours) || null,
      ac_2_5hp_qty: Number(formValues.ac_2_5hp_qty) || null,
      ac_2_5hp_avhours: Number(formValues.ac_2_5hp_avhours) || null,
      ac_3hp_qty: Number(formValues.ac_3hp_qty) || null,
      ac_3hp_avhours: Number(formValues.ac_3hp_avhours) || null,
      small_microwave_qty: Number(formValues.small_microwave_qty) || null,
      small_microwave_avhours:
        Number(formValues.small_microwave_avhours) || null,
      big_microwave_qty: Number(formValues.big_microwave_qty) || null,
      big_microwave_avhours: Number(formValues.big_microwave_avhours) || null,
      heater_1000w_qty: Number(formValues.heater_1000w_qty) || null,
      heater_1000w_avhours: Number(formValues.heater_1000w_avhours) || null,
      heater_1500w_qty: Number(formValues.heater_1500w_qty) || null,
      heater_1500w_avhours: Number(formValues.heater_1500w_avhours) || null,
      heater_2000w_qty: Number(formValues.heater_2000w_qty) || null,
      heater_2000w_avhours: Number(formValues.heater_2000w_avhours) || null,
      small_pressing_iron_qty:
        Number(formValues.small_pressing_iron_qty) || null,
      small_pressing_iron_avhours:
        Number(formValues.small_pressing_iron_avhours) || null,
      big_pressing_iron_qty: Number(formValues.big_pressing_iron_qty) || null,
      big_pressing_iron_avhours:
        Number(formValues.big_pressing_iron_avhours) || null,
      sumo_1hp_qty: Number(formValues.sumo_1hp_qty) || null,
      sumo_1hp_avhours: Number(formValues.sumo_1hp_avhours) || null,
      sumo_1_5hp_qty: Number(formValues.sumo_1_5hp_qty) || null,
      sumo_1_5hp_avhours: Number(formValues.sumo_1_5hp_avhours) || null,
      sumo_2hp_qty: Number(formValues.sumo_2hp_qty) || null,
      sumo_2_5hp_qty: Number(formValues.sumo_2_5hp_qty) || null,
      sumo_2_5hp_avhours: Number(formValues.sumo_2_5hp_avhours) || null,
      sumo_3hp_qty: Number(formValues.sumo_3hp_qty) || null,
      sumo_3hp_avhours: Number(formValues.sumo_3hp_avhours) || null,
    };

    // Check if any of the required parameters are set
    if (
      Object.values(params).some(
        (value) => value !== null && value !== undefined
      )
    ) {
      if (
        selectedUseNationalGrid &&
        (selectedUseNationalGrid === "Yes" ||
          selectedUseNationalGrid === "No") &&
        !selectedState
      ) {
        form.setError("state", {
          type: "manual",
          message: "State of residence is required",
        });
      } else {
        if (selectedState) {
          form.clearErrors("state");

          getPackages(params)
            .unwrap()
            .then((response) => {
              console.log("Packages Response:", response);
              setPackageData(response.data);
              setResponseStatus(response.status);
            })
            .catch((err) => {
              console.error("Error fetching packages:", err);
              setPackageData(err.data);
            });
        }
      }
    }
  }, [
    form,
    selectedState,
    selectedUseNationalGrid,
    formValues.state,
    formValues.nepa_average_hour,
    formValues.bedroom_with_tv_qty,
    formValues.bedroom_with_tv_avhours,
    formValues.bedroom_without_tv_qty,
    formValues.bedroom_without_tv_avhours,
    formValues.ac_15hp_avhours,
    formValues.ac_15hp_qty,
    formValues.ac_1hp_avhours,
    formValues.ac_1hp_qty,
    formValues.ac_2_5hp_avhours,
    formValues.ac_2_5hp_qty,
    formValues.ac_2hp_avhours,
    formValues.ac_2hp_qty,
    formValues.ac_3hp_avhours,
    formValues.ac_3hp_qty,
    formValues.big_microwave_avhours,
    formValues.big_microwave_qty,
    formValues.big_pressing_iron_avhours,
    formValues.big_pressing_iron_qty,
    formValues.dinning_avhours,
    formValues.dinning_qty,
    formValues.double_door_deep_freezer_avhours,
    formValues.double_door_deep_freezer_qty,
    formValues.double_door_fridge_avhours,
    formValues.double_door_fridge_qty,
    formValues.entrance_room_avhour,
    formValues.entrance_room_qty,
    formValues.garage_avhours,
    formValues.garage_qty,
    formValues.heater_1000w_avhours,
    formValues.heater_1000w_qty,
    formValues.heater_1500w_avhours,
    formValues.heater_1500w_qty,
    formValues.heater_2000w_avhours,
    formValues.heater_2000w_qty,
    formValues.home_office_avhours,
    formValues.home_office_qty,
    formValues.kitchen_avhours,
    formValues.kitchen_qty,
    formValues.side_by_side_door_fridge_avhours,
    formValues.side_by_side_door_fridge_qty,
    formValues.single_door_deep_freezer_avhours,
    formValues.single_door_deep_freezer_qty,
    formValues.single_door_fridge_avhours,
    formValues.single_door_fridge_qty,
    formValues.sitting_room_avhours,
    formValues.sitting_room_qty,
    formValues.small_microwave_avhours,
    formValues.small_microwave_qty,
    formValues.small_pressing_iron_avhours,
    formValues.small_pressing_iron_qty,
    formValues.sumo_1_5hp_avhours,
    formValues.sumo_1_5hp_qty,
    formValues.sumo_1hp_avhours,
    formValues.sumo_1hp_qty,
    formValues.sumo_2_5hp_avhours,
    formValues.sumo_2_5hp_qty,
    formValues.sumo_2hp_qty,
    formValues.sumo_3hp_avhours,
    formValues.sumo_3hp_qty,
    formValues.table_top_fridge_avhours,
    formValues.table_top_fridge_qty,
    getPackages,
  ]);

  const selectedBuildingType = form.watch("buildingType");
  const hasBedroomWithTv = form.watch("has_bedroom_with_tv");
  const hasBedroomWithoutTv = form.watch("has_bedroom_without_tv");
  const hasSittingRoom = form.watch("has_sitting_room");
  const hasOtherPlaces = form.watch("has_other_places");

  const nepaAverageHours = form.watch("nepa_average_hour");
  const hasEntranceRoom = form.watch("has_entrance_room");
  const hasDining = form.watch("has_dining");
  const hasKitchen = form.watch("has_kitchen");
  const hasHomeOffice = form.watch("has_home_office");
  const hasGarage = form.watch("has_garage");
  const hasHeavyAppliance = form.watch("has_heavy_appliance");
  const hasFridge = form.watch("has_fridge");
  const hasTableTopFridge = form.watch("has_table_top_fridge");
  const hasSingleDoorFridge = form.watch("has_single_door_fridge");
  const hasDoubleDoorFridge = form.watch("has_double_door_fridge");
  const hasSideBySideDoorFridge = form.watch("has_side_by_side_door_fridge");
  const hasSingleDoorDeepFreezer = form.watch("has_single_door_deep_freezer");
  const hasDoubleDoorDeepFreezer = form.watch("has_double_door_deep_freezer");
  const hasInverterAc = form.watch("has_inverter_ac");
  const has1HpAc = form.watch("has_1hp_ac");
  const has15HpAc = form.watch("has_15hp_ac");
  const has2HpAc = form.watch("has_2hp_ac");
  const has25HpAc = form.watch("has_2_5hp_ac");
  const has3HpAc = form.watch("has_3hp_ac");
  const hasMicrowave = form.watch("has_microwave");
  const hasSmallMicrowave = form.watch("has_small_microwave");
  const hasBigMicrowave = form.watch("has_big_microwave");
  const hasHeater = form.watch("has_heater");
  const hasHeater1000 = form.watch("has_heater_1000w");
  const hasHeater1500 = form.watch("has_heater_1500w");
  const hasHeater2000 = form.watch("has_heater_2000w");
  const hasPressingIron = form.watch("has_pressing_iron");
  const hasSmallPressingIron = form.watch("has_small_pressing_iron");
  const hasBigPressingIron = form.watch("has_big_pressing_iron");
  const hasSumoPump = form.watch("has_sumo_pump");
  const hasSumo1Hp = form.watch("has_sumo_1hp");
  const hasSumo15Hp = form.watch("has_sumo_1_5hp");
  const hasSumo2Hp = form.watch("has_sumo_2hp");
  const hasSumo25Hp = form.watch("has_sumo_2_5hp");
  const hasSumo3Hp = form.watch("has_sumo_3hp");

  useEffect(() => {
    if (selectedUseNationalGrid === "Yes" && nepaAverageHours === "") {
      form.setError("nepa_average_hour", {
        type: "manual",
        message: "Nepa average hour is required when using National Grid",
      });
    } else {
      form.clearErrors("nepa_average_hour");
    }
  }, [selectedUseNationalGrid, nepaAverageHours, form]);

  // useEffect(() => {
  //   const resetFields = (flag: boolean, fields: any) => {
  //     if (!flag) {
  //       fields.forEach((field: any) => {
  //         form.setValue(field, "");
  //       });
  //     }
  //   };

  //   const flagFieldMap: { [key: string]: { flag: boolean; fields: string[] } } =
  //     {
  //       hasBedroomWithoutTv: {
  //         flag: hasBedroomWithoutTv,
  //         fields: ["bedroom_without_tv_qty", "bedroom_without_tv_avhours"],
  //       },
  //       // hasBedroomWithTv: {
  //       //   flag: hasBedroomWithTv,
  //       //   fields: ["bedroom_with_tv_qty", "bedroom_with_tv_avhours"],
  //       // },
  //       hasSittingRoom: {
  //         flag: hasSittingRoom,
  //         fields: ["sitting_room_qty", "sitting_room_avhours"],
  //       },
  //       hasOtherPlaces: {
  //         flag: hasOtherPlaces === "No",
  //         fields: [
  //           "entrance_room_qty",
  //           "entrance_room_avhour",
  //           "dinning_qty",
  //           "dinning_avhours",
  //           "kitchen_qty",
  //           "kitchen_avhours",
  //           "home_office_qty",
  //           "home_office_avhours",
  //           "garage_qty",
  //           "garage_avhours",
  //         ],
  //       },
  //       hasEntranceRoom: {
  //         flag: hasEntranceRoom,
  //         fields: ["entrance_room_qty", "entrance_room_avhour"],
  //       },
  //       hasDining: {
  //         flag: hasDining,
  //         fields: ["dinning_qty", "dinning_avhours"],
  //       },
  //       hasKitchen: {
  //         flag: hasKitchen,
  //         fields: ["kitchen_qty", "kitchen_avhours"],
  //       },
  //       hasHomeOffice: {
  //         flag: hasHomeOffice,
  //         fields: ["home_office_qty", "home_office_avhours"],
  //       },
  //       hasGarage: {
  //         flag: hasGarage,
  //         fields: ["garage_qty", "garage_avhours"],
  //       },

  //       hasHeavyAppliance: {
  //         flag: hasHeavyAppliance === "No",
  //         fields: [
  //           "table_top_fridge_qty",
  //           "table_top_fridge_avhours",
  //           "single_door_fridge_qty",
  //           "single_door_fridge_avhours",
  //           "double_door_fridge_qty",
  //           "double_door_fridge_avhours",
  //           "side_by_side_door_fridge_qty",
  //           "side_by_side_door_fridge_avhours",
  //           "single_door_deep_freezer_qty",
  //           "single_door_deep_freezer_avhours",
  //           "double_door_deep_freezer_qty",
  //           "ac_1hp_qty",
  //           "ac_1hp_avhours",
  //           "ac_15hp_qty",
  //           "ac_15hp_avhours",
  //           "ac_2hp_qty",
  //           "ac_2hp_avhours",
  //           "ac_2_5hp_qty",
  //           "ac_2_5hp_avhours",
  //           "ac_3hp_qty",
  //           "ac_3hp_avhours",
  //           "small_microwave_qty",
  //           "small_microwave_avhours",
  //           "big_microwave_qty",
  //           "big_microwave_avhours",
  //           "heater_1000w_qty",
  //           "heater_1000w_avhours",
  //           "heater_1500w_qty",
  //           "heater_1500w_avhours",
  //           "heater_2000w_qty",
  //           "heater_2000w_avhours",
  //           "small_pressing_iron_qty",
  //           "small_pressing_iron_avhours",
  //           "big_pressing_iron_qty",
  //           "big_pressing_iron_avhours",
  //           "sumo_1hp_qty",
  //           "sumo_1hp_avhours",
  //           "sumo_1_5hp_qty",
  //           "sumo_1_5hp_avhours",
  //           "sumo_2hp_qty",
  //           "sumo_2hp_avhours",
  //           "sumo_2_5hp_qty",
  //           "sumo_2_5hp_avhours",
  //           "sumo_3hp_qty",
  //           "sumo_3hp_avhours",
  //         ],
  //       },
  //       hasFridge: {
  //         flag: hasFridge === "No",
  //         fields: [
  //           "table_top_fridge_qty",
  //           "table_top_fridge_avhours",
  //           "single_door_fridge_qty",
  //           "single_door_fridge_avhours",
  //           "double_door_fridge_qty",
  //           "double_door_fridge_avhours",
  //           "side_by_side_door_fridge_qty",
  //           "side_by_side_door_fridge_avhours",
  //           "single_door_deep_freezer_qty",
  //           "single_door_deep_freezer_avhours",
  //           "double_door_deep_freezer_qty",
  //           "double_door_deep_freezer_avhours",
  //         ],
  //       },

  //       hasTableTopFridge: {
  //         flag: hasTableTopFridge,
  //         fields: ["table_top_fridge_qty", "table_top_fridge_avhours"],
  //       },
  //       hasDoubleDoorFridge: {
  //         flag: hasDoubleDoorFridge,
  //         fields: ["double_door_fridge_qty", "double_door_fridge_avhours"],
  //       },
  //       hasSideBySideDoorFridge: {
  //         flag: hasSideBySideDoorFridge,
  //         fields: ["double_door_fridge_qty", "double_door_fridge_avhours"],
  //       },
  //       hasSingleDoorFridge: {
  //         flag: hasSingleDoorFridge,
  //         fields: ["single_door_fridge_qty", "single_door_fridge_avhours"],
  //       },
  //       hasSingleDoorDeepFreezer: {
  //         flag: hasSingleDoorDeepFreezer,
  //         fields: [
  //           "single_door_deep_freezer_qty",
  //           "single_door_deep_freezer_avhours",
  //         ],
  //       },
  //       hasDoubleDoorDeepFreezer: {
  //         flag: hasDoubleDoorDeepFreezer,
  //         fields: [
  //           "double_door_deep_freezer_qty",
  //           "double_door_deep_freezer_avhours",
  //         ],
  //       },

  //       hasMicrowave: {
  //         flag: hasMicrowave === "No",
  //         fields: [
  //           "small_microwave_qty",
  //           "small_microwave_avhours",
  //           "big_microwave_qty",
  //           "big_microwave_avhours",
  //         ],
  //       },
  //       hasSmallMicrowave: {
  //         flag: hasSmallMicrowave,
  //         fields: ["small_microwave_qty", "small_microwave_avhours"],
  //       },
  //       hasBigMicrowave: {
  //         flag: hasBigMicrowave,
  //         fields: ["big_microwave_qty", "big_microwave_avhours"],
  //       },

  //       hasInverterAc: {
  //         flag: hasInverterAc === "No",
  //         fields: [
  //           "ac_1hp_qty",
  //           "ac_1hp_avhours",
  //           "ac_15hp_qty",
  //           "ac_15hp_avhours",
  //           "ac_2hp_qty",
  //           "ac_2hp_avhours",
  //           "ac_2_5hp_qty",
  //           "ac_2_5hp_avhours",
  //           "ac_3hp_qty",
  //           "ac_3hp_avhours",
  //         ],
  //       },

  //       has1HpAc: {
  //         flag: has1HpAc,
  //         fields: ["ac_1hp_qty", "ac_1hp_avhours"],
  //       },
  //       has15HpAc: {
  //         flag: has15HpAc,
  //         fields: ["ac_15hp_qty", "ac_15hp_avhours"],
  //       },
  //       has2HpAc: {
  //         flag: has2HpAc,
  //         fields: ["ac_2hp_qty", "ac_2hp_avhours"],
  //       },
  //       has25HpAc: {
  //         flag: has25HpAc,
  //         fields: ["ac_2_5hp_qty", "ac_2_5hp_avhours"],
  //       },
  //       has3HpAc: {
  //         flag: has3HpAc,
  //         fields: ["ac_3hp_qty", "ac_3hp_avhours"],
  //       },

  //       hasHeater: {
  //         flag: hasHeater === "No",
  //         fields: [
  //           "heater_1000w_qty",
  //           "heater_1000w_avhours",
  //           "heater_1500w_qty",
  //           "heater_1500w_avhours",
  //           "heater_2000w_qty",
  //           "heater_2000w_avhours",
  //         ],
  //       },
  //       hasHeater1000: {
  //         flag: hasHeater1000,
  //         fields: ["heater_1000w_qty", "heater_1000w_avhours"],
  //       },
  //       hasHeater1500: {
  //         flag: hasHeater1500,
  //         fields: ["heater_1500w_qty", "heater_1500w_avhours"],
  //       },
  //       hasHeater2000: {
  //         flag: hasHeater2000,
  //         fields: ["heater_2000w_qty", "heater_2000w_avhours"],
  //       },

  //       hasPressingIron: {
  //         flag: hasPressingIron === "No",
  //         fields: [
  //           "small_pressing_iron_qty",
  //           "small_pressing_iron_avhours",
  //           "big_pressing_iron_qty",
  //           "big_pressing_iron_avhours",
  //         ],
  //       },

  //       hasSmallPressingIron: {
  //         flag: hasSmallPressingIron,
  //         fields: ["small_pressing_iron_qty", "small_pressing_iron_avhours"],
  //       },
  //       hasBigPressingIron: {
  //         flag: hasBigPressingIron,
  //         fields: ["big_pressing_iron_qty", "big_pressing_iron_avhours"],
  //       },

  //       hasSumoPump: {
  //         flag: hasSumoPump === "No",
  //         fields: [
  //           "sumo_1hp_qty",
  //           "sumo_1hp_avhours",
  //           "sumo_1_5hp_qty",
  //           "sumo_1_5hp_avhours",
  //           "sumo_2hp_qty",
  //           "sumo_2hp_avhours",
  //           "sumo_2_5hp_qty",
  //           "sumo_2_5hp_avhours",
  //           "sumo_3hp_qty",
  //           "sumo_3hp_avhours",
  //         ],
  //       },

  //       hasSumo1Hp: {
  //         flag: hasSumo1Hp,
  //         fields: ["sumo_1hp_qty", "sumo_1hp_avhours"],
  //       },
  //       hasSumo15Hp: {
  //         flag: hasSumo15Hp,
  //         fields: ["sumo_1_5hp_qty", "sumo_1_5hp_avhours"],
  //       },
  //       hasSumo2Hp: {
  //         flag: hasSumo2Hp,
  //         fields: ["sumo_2hp_qty", "sumo_2hp_avhours"],
  //       },
  //       hasSumo25Hp: {
  //         flag: hasSumo25Hp,
  //         fields: ["sumo_2_5hp_qty", "sumo_2_5hp_avhours"],
  //       },
  //       hasSumo3Hp: {
  //         flag: hasSumo3Hp,
  //         fields: ["sumo_3hp_qty", "sumo_3hp_avhours"],
  //       },
  //     };

  //   // Iterate through the object and reset fields as necessary
  //   Object.entries(flagFieldMap).forEach(([_, { flag, fields }]) => {
  //     resetFields(flag, fields);
  //   });

  //   if (hasOtherPlaces === "No") {
  //     form.setValue("has_entrance_room", false);
  //     form.setValue("has_dining", false);
  //     form.setValue("has_kitchen", false);
  //     form.setValue("has_home_office", false);
  //     form.setValue("has_garage", false);
  //   }
  //   if (hasHeavyAppliance === "No") {
  //     form.setValue("has_fridge", "No");
  //     form.setValue("has_inverter_ac", "No");
  //     form.setValue("has_microwave", "No");
  //     form.setValue("has_heater", "No");
  //     form.setValue("has_pressing_iron", "No");
  //     form.setValue("has_sumo_pump", "No");
  //   }
  // }, [
  //   hasBedroomWithoutTv,
  //   hasBedroomWithTv,
  //   hasSittingRoom,
  //   hasOtherPlaces,
  //   hasHeavyAppliance,
  //   form,
  //   hasDining,
  //   hasEntranceRoom,
  //   hasFridge,
  //   hasHeater,
  //   hasInverterAc,
  //   hasKitchen,
  //   hasMicrowave,
  //   hasPressingIron,
  //   hasSumoPump,
  //   hasGarage,
  //   hasHomeOffice,
  //   has15HpAc,
  //   has1HpAc,
  //   has25HpAc,
  //   has2HpAc,
  //   has3HpAc,
  //   hasBigMicrowave,
  //   hasBigPressingIron,
  //   hasDoubleDoorDeepFreezer,
  //   hasDoubleDoorFridge,
  //   hasHeater1000,
  //   hasHeater1500,
  //   hasHeater2000,
  //   hasSideBySideDoorFridge,
  //   hasSingleDoorDeepFreezer,
  //   hasSingleDoorFridge,
  //   hasSmallMicrowave,
  //   hasSmallPressingIron,
  //   hasSumo15Hp,
  //   hasSumo1Hp,
  //   hasSumo25Hp,
  //   hasSumo2Hp,
  //   hasSumo3Hp,
  //   hasTableTopFridge,
  // ]);

  useEffect(() => {
    if (formValues.buildingType === "business") {
      toast.info("Coming soon.");
    }
  }, [formValues.buildingType]);

  // Bedroom with TV
  useEffect(() => {
    if (hasBedroomWithTv) {
      form.setValue("bedroom_with_tv_qty", "1");
      form.setValue("bedroom_with_tv_avhours", "12");
    } else {
      form.setValue("bedroom_with_tv_qty", "");
      form.setValue("bedroom_with_tv_avhours", "");
    }
  }, [hasBedroomWithTv, form]);

  // Bedroom without TV
  useEffect(() => {
    if (hasBedroomWithoutTv) {
      form.setValue("bedroom_without_tv_qty", "1");
      form.setValue("bedroom_without_tv_avhours", "12");
    } else {
      form.setValue("bedroom_without_tv_qty", "");
      form.setValue("bedroom_without_tv_avhours", "");
    }
  }, [hasBedroomWithoutTv, form]);

  // Sitting Room
  useEffect(() => {
    if (hasSittingRoom) {
      form.setValue("sitting_room_qty", "1");
      form.setValue("sitting_room_avhours", "12");
    } else {
      form.setValue("sitting_room_qty", "");
      form.setValue("sitting_room_avhours", "");
    }
  }, [hasSittingRoom, form]);

  // Entrance Room
  useEffect(() => {
    if (hasEntranceRoom) {
      form.setValue("entrance_room_qty", "1");
      form.setValue("entrance_room_avhour", "12");
    } else {
      form.setValue("entrance_room_qty", "");
      form.setValue("entrance_room_avhour", "");
    }
  }, [hasEntranceRoom, form]);

  // Dining
  useEffect(() => {
    if (hasDining) {
      form.setValue("dinning_qty", "1");
      form.setValue("dinning_avhours", "12");
    } else {
      form.setValue("dinning_qty", "");
      form.setValue("dinning_avhours", "");
    }
  }, [hasDining, form]);

  // Kitchen
  useEffect(() => {
    if (hasKitchen) {
      form.setValue("kitchen_qty", "1");
      form.setValue("kitchen_avhours", "12");
    } else {
      form.setValue("kitchen_qty", "");
      form.setValue("kitchen_avhours", "");
    }
  }, [hasKitchen, form]);

  // Home Office
  useEffect(() => {
    if (hasHomeOffice) {
      form.setValue("home_office_qty", "1");
      form.setValue("home_office_avhours", "12");
    } else {
      form.setValue("home_office_qty", "");
      form.setValue("home_office_avhours", "");
    }
  }, [hasHomeOffice, form]);

  // Garage
  useEffect(() => {
    if (hasGarage) {
      form.setValue("garage_qty", "1");
      form.setValue("garage_avhours", "12");
    } else {
      form.setValue("garage_qty", "");
      form.setValue("garage_avhours", "");
    }
  }, [hasGarage, form]);

  // Table Top Fridge
  useEffect(() => {
    if (hasTableTopFridge) {
      form.setValue("table_top_fridge_qty", "1");
      form.setValue("table_top_fridge_avhours", "12");
    } else {
      form.setValue("table_top_fridge_qty", "");
      form.setValue("table_top_fridge_avhours", "");
    }
  }, [hasTableTopFridge, form]);

  // Single Door Fridge
  useEffect(() => {
    if (hasSingleDoorFridge) {
      form.setValue("single_door_fridge_qty", "1");
      form.setValue("single_door_fridge_avhours", "12");
    } else {
      form.setValue("single_door_fridge_qty", "");
      form.setValue("single_door_fridge_avhours", "");
    }
  }, [hasSingleDoorFridge, form]);

  // Double Door Fridge
  useEffect(() => {
    if (hasDoubleDoorFridge) {
      form.setValue("double_door_fridge_qty", "1");
      form.setValue("double_door_fridge_avhours", "12");
    } else {
      form.setValue("double_door_fridge_qty", "");
      form.setValue("double_door_fridge_avhours", "");
    }
  }, [hasDoubleDoorFridge, form]);

  // Side by Side Door Fridge
  useEffect(() => {
    if (hasSideBySideDoorFridge) {
      form.setValue("side_by_side_door_fridge_qty", "1");
      form.setValue("side_by_side_door_fridge_avhours", "12");
    } else {
      form.setValue("side_by_side_door_fridge_qty", "");
      form.setValue("side_by_side_door_fridge_avhours", "");
    }
  }, [hasSideBySideDoorFridge, form]);

  // Single Door Deep Freezer
  useEffect(() => {
    if (hasSingleDoorDeepFreezer) {
      form.setValue("single_door_deep_freezer_qty", "1");
      form.setValue("single_door_deep_freezer_avhours", "12");
    } else {
      form.setValue("single_door_deep_freezer_qty", "");
      form.setValue("single_door_deep_freezer_avhours", "");
    }
  }, [hasSingleDoorDeepFreezer, form]);

  // Double Door Deep Freezer
  useEffect(() => {
    if (hasDoubleDoorDeepFreezer) {
      form.setValue("double_door_deep_freezer_qty", "1");
      form.setValue("double_door_deep_freezer_avhours", "12");
    } else {
      form.setValue("double_door_deep_freezer_qty", "");
      form.setValue("double_door_deep_freezer_avhours", "");
    }
  }, [hasDoubleDoorDeepFreezer, form]);

  // 1HP AC
  useEffect(() => {
    if (has1HpAc) {
      form.setValue("ac_1hp_qty", "1");
      form.setValue("ac_1hp_avhours", "3");
    } else {
      form.setValue("ac_1hp_qty", "");
      form.setValue("ac_1hp_avhours", "");
    }
  }, [has1HpAc, form]);

  // 1.5HP AC
  useEffect(() => {
    if (has15HpAc) {
      form.setValue("ac_15hp_qty", "1");
      form.setValue("ac_15hp_avhours", "3");
    } else {
      form.setValue("ac_15hp_qty", "");
      form.setValue("ac_15hp_avhours", "");
    }
  }, [has15HpAc, form]);

  // 2HP AC
  useEffect(() => {
    if (has2HpAc) {
      form.setValue("ac_2hp_qty", "1");
      form.setValue("ac_2hp_avhours", "3");
    } else {
      form.setValue("ac_2hp_qty", "");
      form.setValue("ac_2hp_avhours", "");
    }
  }, [has2HpAc, form]);

  // 2.5HP AC
  useEffect(() => {
    if (has25HpAc) {
      form.setValue("ac_2_5hp_qty", "1");
      form.setValue("ac_2_5hp_avhours", "3");
    } else {
      form.setValue("ac_2_5hp_qty", "");
      form.setValue("ac_2_5hp_avhours", "");
    }
  }, [has25HpAc, form]);

  // 3HP AC
  useEffect(() => {
    if (has3HpAc) {
      form.setValue("ac_3hp_qty", "1");
      form.setValue("ac_3hp_avhours", "3");
    } else {
      form.setValue("ac_3hp_qty", "");
      form.setValue("ac_3hp_avhours", "");
    }
  }, [has3HpAc, form]);

  // Small Microwave
  useEffect(() => {
    if (hasSmallMicrowave) {
      form.setValue("small_microwave_qty", "1");
      form.setValue("small_microwave_avhours", "0.5");
    } else {
      form.setValue("small_microwave_qty", "");
      form.setValue("small_microwave_avhours", "");
    }
  }, [hasSmallMicrowave, form]);

  // Big Microwave
  useEffect(() => {
    if (hasBigMicrowave) {
      form.setValue("big_microwave_qty", "1");
      form.setValue("big_microwave_avhours", "0.5");
    } else {
      form.setValue("big_microwave_qty", "");
      form.setValue("big_microwave_avhours", "");
    }
  }, [hasBigMicrowave, form]);

  // 1000W Heater
  useEffect(() => {
    if (hasHeater1000) {
      form.setValue("heater_1000w_qty", "1");
      form.setValue("heater_1000w_avhours", "0.5");
    } else {
      form.setValue("heater_1000w_qty", "");
      form.setValue("heater_1000w_avhours", "");
    }
  }, [hasHeater1000, form]);

  // 1500W Heater
  useEffect(() => {
    if (hasHeater1500) {
      form.setValue("heater_1500w_qty", "1");
      form.setValue("heater_1500w_avhours", "12");
    } else {
      form.setValue("heater_1500w_qty", "");
      form.setValue("heater_1500w_avhours", "");
    }
  }, [hasHeater1500, form]);

  // 2000W Heater
  useEffect(() => {
    if (hasHeater2000) {
      form.setValue("heater_2000w_qty", "1");
      form.setValue("heater_2000w_avhours", "12");
    } else {
      form.setValue("heater_2000w_qty", "");
      form.setValue("heater_2000w_avhours", "");
    }
  }, [hasHeater2000, form]);

  // Small Pressing Iron
  useEffect(() => {
    if (hasSmallPressingIron) {
      form.setValue("small_pressing_iron_qty", "1");
      form.setValue("small_pressing_iron_avhours", "1");
    } else {
      form.setValue("small_pressing_iron_qty", "");
      form.setValue("small_pressing_iron_avhours", "");
    }
  }, [hasSmallPressingIron, form]);

  // Big Pressing Iron
  useEffect(() => {
    if (hasBigPressingIron) {
      form.setValue("big_pressing_iron_qty", "1");
      form.setValue("big_pressing_iron_avhours", "12");
    } else {
      form.setValue("big_pressing_iron_qty", "");
      form.setValue("big_pressing_iron_avhours", "");
    }
  }, [hasBigPressingIron, form]);

  // 1HP Sumo
  useEffect(() => {
    if (hasSumo1Hp) {
      form.setValue("sumo_1hp_qty", "1");
      form.setValue("sumo_1hp_avhours", "1");
    } else {
      form.setValue("sumo_1hp_qty", "");
      form.setValue("sumo_1hp_avhours", "");
    }
  }, [hasSumo1Hp, form]);

  // 1.5HP Sumo
  useEffect(() => {
    if (hasSumo15Hp) {
      form.setValue("sumo_1_5hp_qty", "1");
      form.setValue("sumo_1_5hp_avhours", "1");
    } else {
      form.setValue("sumo_1_5hp_qty", "");
      form.setValue("sumo_1_5hp_avhours", "");
    }
  }, [hasSumo15Hp, form]);

  // 2HP Sumo
  useEffect(() => {
    if (hasSumo2Hp) {
      form.setValue("sumo_2hp_qty", "1");
      form.setValue("sumo_2hp_avhours", "1");
    } else {
      form.setValue("sumo_2hp_qty", "");
      form.setValue("sumo_2hp_avhours", "");
    }
  }, [hasSumo2Hp, form]);

  // 2.5HP Sumo
  useEffect(() => {
    if (hasSumo25Hp) {
      form.setValue("sumo_2_5hp_qty", "1");
      form.setValue("sumo_2_5hp_avhours", "1");
    } else {
      form.setValue("sumo_2_5hp_qty", "");
      form.setValue("sumo_2_5hp_avhours", "");
    }
  }, [hasSumo25Hp, form]);

  // 3HP Sumo
  useEffect(() => {
    if (hasSumo3Hp) {
      form.setValue("sumo_3hp_qty", "1");
      form.setValue("sumo_3hp_avhours", "1");
    } else {
      form.setValue("sumo_3hp_qty", "");
      form.setValue("sumo_3hp_avhours", "");
    }
  }, [hasSumo3Hp, form]);

  // useEffect(() => {
  //   if (hasBedroomWithTv) {
  //     form.setValue("bedroom_with_tv_qty", "1");
  //     form.setValue("bedroom_with_tv_avhours", "12");
  //   } else {
  //     form.setValue("bedroom_with_tv_qty", "");
  //     form.setValue("bedroom_with_tv_avhours", "");
  //   }
  //   if (hasBedroomWithoutTv) {
  //     form.setValue("bedroom_without_tv_qty", "1");
  //     form.setValue("bedroom_without_tv_avhours", "12");
  //   } else {
  //     form.setValue("bedroom_without_tv_qty", "");
  //     form.setValue("bedroom_without_tv_avhours", "");
  //   }
  //   if (hasSittingRoom) {
  //     form.setValue("sitting_room_qty", "1");
  //     form.setValue("sitting_room_avhours", "12");
  //   } else {
  //     form.setValue("sitting_room_qty", "");
  //     form.setValue("sitting_room_avhours", "");
  //   }
  //   if (hasEntranceRoom) {
  //     form.setValue("entrance_room_qty", "1");
  //     form.setValue("entrance_room_avhour", "12");
  //   } else {
  //     form.setValue("entrance_room_qty", "");
  //     form.setValue("entrance_room_avhour", "");
  //   }
  //   if (hasDining) {
  //     form.setValue("dinning_qty", "1");
  //     form.setValue("dinning_avhours", "12");
  //   } else {
  //     form.setValue("dinning_qty", "");
  //     form.setValue("dinning_avhours", "");
  //   }
  //   if (hasKitchen) {
  //     form.setValue("kitchen_qty", "1");
  //     form.setValue("kitchen_avhours", "12");
  //   } else {
  //     form.setValue("kitchen_qty", "");
  //     form.setValue("kitchen_avhours", "");
  //   }
  //   if (hasHomeOffice) {
  //     form.setValue("home_office_qty", "1");
  //     form.setValue("home_office_avhours", "12");
  //   } else {
  //     form.setValue("home_office_qty", "");
  //     form.setValue("home_office_avhours", "");
  //   }
  //   if (hasGarage) {
  //     form.setValue("garage_qty", "1");
  //     form.setValue("garage_avhours", "12");
  //   } else {
  //     form.setValue("garage_qty", "");
  //     form.setValue("garage_avhours", "");
  //   }
  //   if (hasTableTopFridge) {
  //     form.setValue("table_top_fridge_qty", "1");
  //     form.setValue("table_top_fridge_avhours", "12");
  //   } else {
  //     form.setValue("table_top_fridge_qty", "");
  //     form.setValue("table_top_fridge_avhours", "");
  //   }
  //   if (hasSingleDoorFridge) {
  //     form.setValue("single_door_fridge_qty", "1");
  //     form.setValue("single_door_fridge_avhours", "12");
  //   } else {
  //     form.setValue("single_door_fridge_qty", "");
  //     form.setValue("single_door_fridge_avhours", "");
  //   }
  //   if (hasDoubleDoorFridge) {
  //     form.setValue("double_door_fridge_qty", "1");
  //     form.setValue("double_door_fridge_avhours", "12");
  //   } else {
  //     form.setValue("double_door_fridge_qty", "");
  //     form.setValue("double_door_fridge_avhours", "");
  //   }
  //   if (hasSideBySideDoorFridge) {
  //     form.setValue("side_by_side_door_fridge_qty", "1");
  //     form.setValue("side_by_side_door_fridge_avhours", "12");
  //   } else {
  //     form.setValue("side_by_side_door_fridge_qty", "");
  //     form.setValue("side_by_side_door_fridge_avhours", "");
  //   }
  //   if (hasSingleDoorDeepFreezer) {
  //     form.setValue("single_door_deep_freezer_qty", "1");
  //     form.setValue("single_door_deep_freezer_avhours", "12");
  //   } else {
  //     form.setValue("single_door_deep_freezer_qty", "");
  //     form.setValue("single_door_deep_freezer_avhours", "");
  //   }
  //   if (hasDoubleDoorDeepFreezer) {
  //     form.setValue("double_door_deep_freezer_qty", "1");
  //     form.setValue("double_door_deep_freezer_avhours", "12");
  //   } else {
  //     form.setValue("double_door_deep_freezer_qty", "");
  //     form.setValue("double_door_deep_freezer_avhours", "");
  //   }
  //   if (has1HpAc) {
  //     form.setValue("ac_1hp_qty", "1");
  //     form.setValue("ac_1hp_avhours", "3");
  //   } else {
  //     form.setValue("ac_1hp_qty", "");
  //     form.setValue("ac_1hp_avhours", "");
  //   }
  //   if (has15HpAc) {
  //     form.setValue("ac_15hp_qty", "1");
  //     form.setValue("ac_15hp_avhours", "3");
  //   } else {
  //     form.setValue("ac_15hp_qty", "");
  //     form.setValue("ac_15hp_avhours", "");
  //   }
  //   if (has2HpAc) {
  //     form.setValue("ac_2hp_qty", "1");
  //     form.setValue("ac_2hp_avhours", "3");
  //   } else {
  //     form.setValue("ac_2hp_qty", "");
  //     form.setValue("ac_2hp_avhours", "");
  //   }
  //   if (has25HpAc) {
  //     form.setValue("ac_2_5hp_qty", "1");
  //     form.setValue("ac_2_5hp_avhours", "3");
  //   } else {
  //     form.setValue("ac_2_5hp_qty", "");
  //     form.setValue("ac_2_5hp_avhours", "");
  //   }
  //   if (has3HpAc) {
  //     form.setValue("ac_3hp_qty", "1");
  //     form.setValue("ac_3hp_avhours", "3");
  //   } else {
  //     form.setValue("ac_3hp_qty", "");
  //     form.setValue("ac_3hp_avhours", "");
  //   }
  //   if (hasSmallMicrowave) {
  //     form.setValue("small_microwave_qty", "1");
  //     form.setValue("small_microwave_avhours", "0.5");
  //   } else {
  //     form.setValue("small_microwave_qty", "");
  //     form.setValue("small_microwave_avhours", "");
  //   }
  //   if (hasBigMicrowave) {
  //     form.setValue("big_microwave_qty", "1");
  //     form.setValue("big_microwave_avhours", "0.5");
  //   } else {
  //     form.setValue("big_microwave_qty", "");
  //     form.setValue("big_microwave_avhours", "");
  //   }
  //   if (hasHeater1000) {
  //     form.setValue("heater_1000w_qty", "1");
  //     form.setValue("heater_1000w_avhours", "0.5");
  //   } else {
  //     form.setValue("heater_1000w_qty", "");
  //     form.setValue("heater_1000w_avhours", "");
  //   }
  //   if (hasHeater1500) {
  //     form.setValue("heater_1500w_qty", "1");
  //     form.setValue("heater_1500w_avhours", "12");
  //   } else {
  //     form.setValue("heater_1500w_qty", "");
  //     form.setValue("heater_1500w_avhours", "");
  //   }
  //   if (hasHeater2000) {
  //     form.setValue("heater_2000w_qty", "1");
  //     form.setValue("heater_2000w_avhours", "12");
  //   } else {
  //     form.setValue("heater_2000w_qty", "");
  //     form.setValue("heater_2000w_avhours", "");
  //   }
  //   if (hasSmallPressingIron) {
  //     form.setValue("small_pressing_iron_qty", "1");
  //     form.setValue("small_pressing_iron_avhours", "1");
  //   } else {
  //     form.setValue("small_pressing_iron_qty", "");
  //     form.setValue("small_pressing_iron_avhours", "");
  //   }
  //   if (hasBigPressingIron) {
  //     form.setValue("big_pressing_iron_qty", "1");
  //     form.setValue("big_pressing_iron_avhours", "12");
  //   } else {
  //     form.setValue("big_pressing_iron_qty", "");
  //     form.setValue("big_pressing_iron_avhours", "");
  //   }
  //   if (hasSumo1Hp) {
  //     form.setValue("sumo_1hp_qty", "1");
  //     form.setValue("sumo_1hp_avhours", "1");
  //   } else {
  //     form.setValue("sumo_1hp_qty", "");
  //     form.setValue("sumo_1hp_avhours", "");
  //   }
  //   if (hasSumo15Hp) {
  //     form.setValue("sumo_1_5hp_qty", "1");
  //     form.setValue("sumo_1_5hp_avhours", "1");
  //   } else {
  //     form.setValue("sumo_1_5hp_qty", "");
  //     form.setValue("sumo_1_5hp_avhours", "");
  //   }

  //   if (hasSumo2Hp) {
  //     form.setValue("sumo_2hp_qty", "1");
  //     form.setValue("sumo_2hp_avhours", "1");
  //   } else {
  //     form.setValue("sumo_2hp_qty", "");
  //     form.setValue("sumo_2hp_avhours", "");
  //   }
  //   if (hasSumo25Hp) {
  //     form.setValue("sumo_2_5hp_qty", "1");
  //     form.setValue("sumo_2_5hp_avhours", "1");
  //   } else {
  //     form.setValue("sumo_2_5hp_qty", "");
  //     form.setValue("sumo_2_5hp_avhours", "");
  //   }
  //   if (hasSumo3Hp) {
  //     form.setValue("sumo_3hp_qty", "1");
  //     form.setValue("sumo_3hp_avhours", "1");
  //   } else {
  //     form.setValue("sumo_3hp_qty", "");
  //     form.setValue("sumo_3hp_avhours", "");
  //   }
  // }, [
  //   hasBedroomWithTv,
  //   form,
  //   has15HpAc,
  //   has1HpAc,
  //   has25HpAc,
  //   has2HpAc,
  //   has3HpAc,
  //   hasBedroomWithoutTv,
  //   hasBigMicrowave,
  //   hasBigPressingIron,
  //   hasDining,
  //   hasDoubleDoorDeepFreezer,
  //   hasDoubleDoorFridge,
  //   hasEntranceRoom,
  //   hasGarage,
  //   hasHeater1000,
  //   hasHeater1500,
  //   hasHeater2000,
  //   hasHomeOffice,
  //   hasKitchen,
  //   hasSideBySideDoorFridge,
  //   hasSingleDoorDeepFreezer,
  //   hasSingleDoorFridge,
  //   hasSittingRoom,
  //   hasSmallMicrowave,
  //   hasSmallPressingIron,
  //   hasSumo15Hp,
  //   hasSumo1Hp,
  //   hasSumo25Hp,
  //   hasSumo2Hp,
  //   hasSumo3Hp,
  //   hasTableTopFridge,
  // ]);

  return (
    <MaxWidthWrapper>
      <div className="w-full relative py-10">
        <div
          className={cn(
            "w-full relative flex",
            packageData && mobileWidth ? "flex-col" : "flex-col-reverse",
            "md:flex-row gap-y-8 md:gap-y-0 md:gap-x-24"
          )}
        >
          {" "}
          <div className="w-full md:w-[65%]">
            <Form {...form}>
              <form className="flex flex-col space-y-3 items-start w-full">
                <FormField
                  control={form.control}
                  name="state"
                  render={({ field }) => (
                    <FormItem className="w-full border-0 border-none flex items-start flex-col p-0">
                      <FormLabel className="text-[#ffffff]">
                        Where do you reside?
                      </FormLabel>
                      <Popover open={open} onOpenChange={setOpen}>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              //   disabled={!!editMode}
                              variant="outline"
                              role="combobox"
                              size={"sm"}
                              className={cn(
                                "bg-[#DEEDCC] w-full !text-primary font-[400] justify-between border-0 border-none",
                                !field.value && "text-muted-foreground"
                              )}
                            >
                              {field.value
                                ? states.find(
                                    (state: State) =>
                                      state.state_id === field.value
                                  )?.name
                                : "Select State"}
                              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className=" p-0">
                          <Command>
                            <CommandInput placeholder="Search state..." />
                            <CommandEmpty>No state found.</CommandEmpty>
                            <CommandGroup>
                              <CommandList>
                                {states.map((state: State) => (
                                  <CommandItem
                                    value={state.name}
                                    key={state.state_id}
                                    onSelect={() => {
                                      form.setValue("state", state.state_id);
                                      // handleStateSelect(state.state_id);
                                      setOpen(false);
                                    }}
                                  >
                                    <Check
                                      className={cn(
                                        "mr-2 h-4 w-4",
                                        state.state_id === field.value
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    {state.name}
                                  </CommandItem>
                                ))}
                              </CommandList>
                            </CommandGroup>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="useNationalGrid"
                  render={({ field }) => (
                    <FormItem className="w-full border-0 border-none flex items-start flex-col p-0">
                      <FormLabel className="text-white text-left">
                        Do you use NEPA (National Grid)
                      </FormLabel>
                      <Select
                        onValueChange={(value) => {
                          field.onChange(value);
                          // setUseNationalGrid(value);
                        }}
                        defaultValue={field.value}
                        value={field.value}
                      >
                        <FormControl className="bg-[#DEEDCC] w-full">
                          <SelectTrigger>
                            <SelectValue placeholder="Select" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="Yes">Yes</SelectItem>
                          <SelectItem value="No">No</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {selectedUseNationalGrid === "Yes" && (
                  <FormField
                    control={form.control}
                    name="nepa_average_hour"
                    render={({ field }) => (
                      <FormItem className="w-full border-0 border-none flex items-start flex-col p-0">
                        <FormLabel className="text-white text-left">
                          For how many hours on average
                        </FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          value={field.value}
                        >
                          <FormControl className="bg-[#DEEDCC] w-full">
                            <SelectTrigger>
                              <SelectValue placeholder="How many hours on average" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value="1.5">1-2 hours</SelectItem>
                            <SelectItem value="3.5">3-4 hours</SelectItem>
                            <SelectItem value="5.5">5-6 hours</SelectItem>
                            <SelectItem value="7.5">7-8 hours</SelectItem>
                            <SelectItem value="9.5">9-10 hours</SelectItem>
                            <SelectItem value="11.5">11-12 hours</SelectItem>
                            <SelectItem value="13.5">13-14 hours</SelectItem>
                            <SelectItem value="15.5">15-16 hours</SelectItem>
                            <SelectItem value="17.5">17-18 hours</SelectItem>
                            <SelectItem value="19.5">19-20 hours</SelectItem>
                            <SelectItem value="21.5">21-22 hours</SelectItem>
                            <SelectItem value="23.5">23-24 hours</SelectItem>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
                <FormField
                  control={form.control}
                  name="buildingType"
                  render={({ field }) => (
                    <FormItem className="w-full border-0 border-none flex items-start flex-col p-0">
                      <FormLabel className="text-white text-left">
                        What do you want to power?
                      </FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        value={field.value}
                      >
                        <FormControl className="bg-[#DEEDCC] w-full">
                          <SelectTrigger>
                            <SelectValue placeholder="Select" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="residential">
                            Residential
                          </SelectItem>
                          <SelectItem value="business">Business</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {selectedBuildingType === "residential" && (
                  <div className=" w-full">
                    <div className="w-full">
                      <div className="grid grid-cols-3 items-center w-full">
                        <div className="col-span-2 w-full">
                          <FormField
                            control={form.control}
                            name="has_bedroom_with_tv"
                            render={({ field }) => (
                              <FormItem className="flex flex-row items-center justify-between">
                                <FormLabel className="text-white text-base">
                                  Bedroom with TV
                                </FormLabel>

                                <FormControl>
                                  <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        </div>
                      </div>

                      {hasBedroomWithTv && (
                        <div className="grid grid-cols-2 w-full">
                          <div>
                            <FormField
                              control={form.control}
                              name="bedroom_with_tv_qty"
                              render={({ field }) => (
                                <FormItem className="flex items-center space-x-3">
                                  <FormLabel className="text-white text-base">
                                    Quantity
                                  </FormLabel>

                                  <FormControl>
                                    <Input
                                      {...field}
                                      className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                      type="number"
                                      min="1"
                                      placeholder="1 "
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div>
                            <FormField
                              control={form.control}
                              name="bedroom_with_tv_avhours"
                              render={({ field }) => (
                                <FormItem className="flex items-center space-x-3">
                                  <FormLabel className="text-white text-base">
                                    Daily usage
                                  </FormLabel>

                                  <FormControl>
                                    <Input
                                      {...field}
                                      className="max-w-[65px] bg-[#DEEDCC]"
                                      type="number"
                                      min="1"
                                      placeholder="1 "
                                    />
                                  </FormControl>
                                  <FormLabel className="text-white text-base">
                                    hrs
                                  </FormLabel>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-full">
                      <div className="grid grid-cols-3 items-center w-full">
                        <div className="col-span-2 w-full">
                          <FormField
                            control={form.control}
                            name="has_bedroom_without_tv"
                            render={({ field }) => (
                              <FormItem className="flex flex-row items-center justify-between">
                                <FormLabel className="text-white text-base">
                                  Bedroom without TV
                                </FormLabel>

                                <FormControl>
                                  <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        </div>
                      </div>

                      {hasBedroomWithoutTv && (
                        <div className="grid grid-cols-2 w-full">
                          <div>
                            <FormField
                              control={form.control}
                              name="bedroom_without_tv_qty"
                              render={({ field }) => (
                                <FormItem className="flex items-center space-x-3">
                                  <FormLabel className="text-white text-base">
                                    Quantity
                                  </FormLabel>

                                  <FormControl>
                                    <Input
                                      {...field}
                                      className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                      type="number"
                                      min="1"
                                      placeholder="1 "
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div>
                            <FormField
                              control={form.control}
                              name="bedroom_without_tv_avhours"
                              render={({ field }) => (
                                <FormItem className="flex items-center space-x-3">
                                  <FormLabel className="text-white text-base">
                                    Daily usage
                                  </FormLabel>

                                  <FormControl>
                                    <Input
                                      {...field}
                                      className="max-w-[65px] bg-[#DEEDCC]"
                                      type="number"
                                      min="1"
                                      placeholder="1 "
                                    />
                                  </FormControl>
                                  <FormLabel className="text-white text-base">
                                    hrs
                                  </FormLabel>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-full">
                      <div className="grid grid-cols-3 items-center w-full">
                        <div className="col-span-2 w-full">
                          <FormField
                            control={form.control}
                            name="has_sitting_room"
                            render={({ field }) => (
                              <FormItem className="flex flex-row items-center justify-between">
                                <FormLabel className="text-white text-base">
                                  Sitting room
                                </FormLabel>

                                <FormControl>
                                  <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        </div>
                      </div>

                      {hasSittingRoom && (
                        <div className="grid grid-cols-2 w-full">
                          <div>
                            <FormField
                              control={form.control}
                              name="sitting_room_qty"
                              render={({ field }) => (
                                <FormItem className="flex items-center space-x-3">
                                  <FormLabel className="text-white text-base">
                                    Quantity
                                  </FormLabel>

                                  <FormControl>
                                    <Input
                                      {...field}
                                      className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                      type="number"
                                      min="1"
                                      placeholder="1 "
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div>
                            <FormField
                              control={form.control}
                              name="sitting_room_avhours"
                              render={({ field }) => (
                                <FormItem className="flex items-center space-x-3">
                                  <FormLabel className="text-white text-base">
                                    Daily usage
                                  </FormLabel>

                                  <FormControl>
                                    <Input
                                      {...field}
                                      className="max-w-[65px] bg-[#DEEDCC]"
                                      type="number"
                                      min="1"
                                      placeholder="1 "
                                    />
                                  </FormControl>
                                  <FormLabel className="text-white text-base">
                                    hrs
                                  </FormLabel>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <FormField
                      control={form.control}
                      name="has_other_places"
                      render={({ field }) => (
                        <FormItem className="mt-1.5 w-full border-0 border-none flex items-start flex-col p-0">
                          <FormLabel className="text-white text-left">
                            Still have other places to power?
                          </FormLabel>
                          <Select
                            onValueChange={(value) => {
                              field.onChange(value);
                              // setUseNationalGrid(value);
                            }}
                            defaultValue={field.value}
                            value={field.value}
                          >
                            <FormControl className="bg-[#DEEDCC] w-full">
                              <SelectTrigger>
                                <SelectValue placeholder="Select" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectItem value="Yes">Yes</SelectItem>
                              <SelectItem value="No">No</SelectItem>
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                )}

                {hasOtherPlaces === "Yes" && (
                  <div className=" w-full">
                    <div className="w-full">
                      <div className="grid grid-cols-3 items-center w-full">
                        <div className="col-span-2 w-full">
                          <FormField
                            control={form.control}
                            name="has_entrance_room"
                            render={({ field }) => (
                              <FormItem className="flex flex-row items-center justify-between">
                                <FormLabel className="text-white text-base">
                                  Entrance Room
                                </FormLabel>

                                <FormControl>
                                  <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        </div>
                      </div>

                      {hasEntranceRoom && (
                        <div className="grid grid-cols-2 w-full">
                          <div>
                            <FormField
                              control={form.control}
                              name="entrance_room_qty"
                              render={({ field }) => (
                                <FormItem className="flex items-center space-x-3">
                                  <FormLabel className="text-white text-base">
                                    Quantity
                                  </FormLabel>

                                  <FormControl>
                                    <Input
                                      {...field}
                                      className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                      type="number"
                                      min="1"
                                      placeholder="1 "
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div>
                            <FormField
                              control={form.control}
                              name="entrance_room_avhour"
                              render={({ field }) => (
                                <FormItem className="flex items-center space-x-3">
                                  <FormLabel className="text-white text-base">
                                    Daily usage
                                  </FormLabel>

                                  <FormControl>
                                    <Input
                                      {...field}
                                      className="max-w-[65px] bg-[#DEEDCC]"
                                      type="number"
                                      min="1"
                                      placeholder="1 "
                                    />
                                  </FormControl>
                                  <FormLabel className="text-white text-base">
                                    hrs
                                  </FormLabel>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-full">
                      <div className="grid grid-cols-3 items-center w-full">
                        <div className="col-span-2 w-full">
                          <FormField
                            control={form.control}
                            name="has_dining"
                            render={({ field }) => (
                              <FormItem className="flex flex-row items-center justify-between">
                                <FormLabel className="text-white text-base">
                                  Dining
                                </FormLabel>

                                <FormControl>
                                  <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        </div>
                      </div>

                      {hasDining && (
                        <div className="grid grid-cols-2 w-full">
                          <div>
                            <FormField
                              control={form.control}
                              name="dinning_qty"
                              render={({ field }) => (
                                <FormItem className="flex items-center space-x-3">
                                  <FormLabel className="text-white text-base">
                                    Quantity
                                  </FormLabel>

                                  <FormControl>
                                    <Input
                                      {...field}
                                      className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                      type="number"
                                      min="1"
                                      placeholder="1 "
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div>
                            <FormField
                              control={form.control}
                              name="dinning_avhours"
                              render={({ field }) => (
                                <FormItem className="flex items-center space-x-3">
                                  <FormLabel className="text-white text-base">
                                    Daily usage
                                  </FormLabel>

                                  <FormControl>
                                    <Input
                                      {...field}
                                      className="max-w-[65px] bg-[#DEEDCC]"
                                      type="number"
                                      min="1"
                                      placeholder="1 "
                                    />
                                  </FormControl>
                                  <FormLabel className="text-white text-base">
                                    hrs
                                  </FormLabel>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-full">
                      <div className="grid grid-cols-3 items-center w-full">
                        <div className="col-span-2 w-full">
                          <FormField
                            control={form.control}
                            name="has_kitchen"
                            render={({ field }) => (
                              <FormItem className="flex flex-row items-center justify-between">
                                <FormLabel className="text-white text-base">
                                  Kitchen
                                </FormLabel>

                                <FormControl>
                                  <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        </div>
                      </div>

                      {hasKitchen && (
                        <div className="grid grid-cols-2 w-full">
                          <div>
                            <FormField
                              control={form.control}
                              name="kitchen_qty"
                              render={({ field }) => (
                                <FormItem className="flex items-center space-x-3">
                                  <FormLabel className="text-white text-base">
                                    Quantity
                                  </FormLabel>

                                  <FormControl>
                                    <Input
                                      {...field}
                                      className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                      type="number"
                                      min="1"
                                      placeholder="1 "
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div>
                            <FormField
                              control={form.control}
                              name="kitchen_avhours"
                              render={({ field }) => (
                                <FormItem className="flex items-center space-x-3">
                                  <FormLabel className="text-white text-base">
                                    Daily usage
                                  </FormLabel>

                                  <FormControl>
                                    <Input
                                      {...field}
                                      className="max-w-[65px] bg-[#DEEDCC]"
                                      type="number"
                                      min="1"
                                      placeholder="1 "
                                    />
                                  </FormControl>
                                  <FormLabel className="text-white text-base">
                                    hrs
                                  </FormLabel>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-full">
                      <div className="grid grid-cols-3 items-center w-full">
                        <div className="col-span-2 w-full">
                          <FormField
                            control={form.control}
                            name="has_home_office"
                            render={({ field }) => (
                              <FormItem className="flex flex-row items-center justify-between">
                                <FormLabel className="text-white text-base">
                                  Home Office
                                </FormLabel>

                                <FormControl>
                                  <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        </div>
                      </div>

                      {hasHomeOffice && (
                        <div className="grid grid-cols-2 w-full">
                          <div>
                            <FormField
                              control={form.control}
                              name="home_office_qty"
                              render={({ field }) => (
                                <FormItem className="flex items-center space-x-3">
                                  <FormLabel className="text-white text-base">
                                    Quantity
                                  </FormLabel>

                                  <FormControl>
                                    <Input
                                      {...field}
                                      className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                      type="number"
                                      min="1"
                                      placeholder="1 "
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div>
                            <FormField
                              control={form.control}
                              name="home_office_avhours"
                              render={({ field }) => (
                                <FormItem className="flex items-center space-x-3">
                                  <FormLabel className="text-white text-base">
                                    Daily usage
                                  </FormLabel>

                                  <FormControl>
                                    <Input
                                      {...field}
                                      className="max-w-[65px] bg-[#DEEDCC]"
                                      type="number"
                                      min="1"
                                      placeholder="1 "
                                    />
                                  </FormControl>
                                  <FormLabel className="text-white text-base">
                                    hrs
                                  </FormLabel>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-full">
                      <div className="grid grid-cols-3 items-center w-full">
                        <div className="col-span-2 w-full">
                          <FormField
                            control={form.control}
                            name="has_garage"
                            render={({ field }) => (
                              <FormItem className="flex flex-row items-center justify-between">
                                <FormLabel className="text-white text-base">
                                  Garage
                                </FormLabel>

                                <FormControl>
                                  <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        </div>
                      </div>

                      {hasGarage && (
                        <div className="grid grid-cols-2 w-full">
                          <div>
                            <FormField
                              control={form.control}
                              name="garage_qty"
                              render={({ field }) => (
                                <FormItem className="flex items-center space-x-3">
                                  <FormLabel className="text-white text-base">
                                    Quantity
                                  </FormLabel>

                                  <FormControl>
                                    <Input
                                      {...field}
                                      className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                      type="number"
                                      min="1"
                                      placeholder="1 "
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div>
                            <FormField
                              control={form.control}
                              name="garage_avhours"
                              render={({ field }) => (
                                <FormItem className="flex items-center space-x-3">
                                  <FormLabel className="text-white text-base">
                                    Daily usage
                                  </FormLabel>

                                  <FormControl>
                                    <Input
                                      {...field}
                                      className="max-w-[65px] bg-[#DEEDCC]"
                                      type="number"
                                      min="1"
                                      placeholder="1 "
                                    />
                                  </FormControl>
                                  <FormLabel className="text-white text-base">
                                    hrs
                                  </FormLabel>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <FormField
                      control={form.control}
                      name="has_heavy_appliance"
                      render={({ field }) => (
                        <FormItem className="mt-1.5 w-full border-0 border-none flex items-start flex-col p-0">
                          <FormLabel className="text-white text-left">
                            Want to use Heavy Appliance?
                          </FormLabel>
                          <Select
                            onValueChange={(value) => {
                              field.onChange(value);
                              // setUseNationalGrid(value);
                            }}
                            defaultValue={field.value}
                            value={field.value}
                          >
                            <FormControl className="bg-[#DEEDCC] w-full">
                              <SelectTrigger>
                                <SelectValue placeholder="Select" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectItem value="Yes">Yes</SelectItem>
                              <SelectItem value="No">No</SelectItem>
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                )}

                {hasHeavyAppliance === "Yes" && (
                  <div className="w-full">
                    {/* fridge */}
                    <div>
                      <FormField
                        control={form.control}
                        name="has_fridge"
                        render={({ field }) => (
                          <FormItem className="mt-1.5 w-full border-0 border-none flex items-start flex-col p-0">
                            <FormLabel className="text-white text-left">
                              Want to use Fridge (s)
                            </FormLabel>
                            <Select
                              onValueChange={(value) => {
                                field.onChange(value);
                                // setUseNationalGrid(value);
                              }}
                              defaultValue={field.value}
                              value={field.value}
                            >
                              <FormControl className="bg-[#DEEDCC] w-full">
                                <SelectTrigger>
                                  <SelectValue placeholder="Select" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                <SelectItem value="Yes">Yes</SelectItem>
                                <SelectItem value="No">No</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      {/* options fridge */}
                      {hasFridge === "Yes" && (
                        <div className="mt-1">
                          {/* option 1 */}
                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_table_top_fridge"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        Table top fridge
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasTableTopFridge && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="table_top_fridge_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="table_top_fridge_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          {/* option2  */}

                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_single_door_fridge"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        Single door fridge
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasSingleDoorFridge && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="single_door_fridge_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="single_door_fridge_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          {/* option */}
                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_double_door_fridge"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        Double door fridge
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasDoubleDoorFridge && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="double_door_fridge_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="double_door_fridge_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_side_by_side_door_fridge"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        Side by side door fridge
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasSideBySideDoorFridge && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="side_by_side_door_fridge_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="side_by_side_door_fridge_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_single_door_deep_freezer"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        Single door deep freezer
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasSingleDoorDeepFreezer && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="single_door_deep_freezer_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="single_door_deep_freezer_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_double_door_deep_freezer"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        Double door Deep freezer
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasDoubleDoorDeepFreezer && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="double_door_deep_freezer_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="double_door_deep_freezer_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    {/* inverter ac */}
                    <div>
                      <FormField
                        control={form.control}
                        name="has_inverter_ac"
                        render={({ field }) => (
                          <FormItem className="mt-1.5 w-full border-0 border-none flex items-start flex-col p-0">
                            <FormLabel className="text-white text-left">
                              Want to use Inverter AC?
                            </FormLabel>
                            <Select
                              onValueChange={(value) => {
                                field.onChange(value);
                                // setUseNationalGrid(value);
                              }}
                              defaultValue={field.value}
                              value={field.value}
                            >
                              <FormControl className="bg-[#DEEDCC] w-full">
                                <SelectTrigger>
                                  <SelectValue placeholder="Select" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                <SelectItem value="Yes">Yes</SelectItem>
                                <SelectItem value="No">No</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      {/* options inverter ac */}
                      {hasInverterAc === "Yes" && (
                        <div className="mt-1">
                          {/* option 1 */}
                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_1hp_ac"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        1HP
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {has1HpAc && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="ac_1hp_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="ac_1hp_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          {/* option2  */}

                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_15hp_ac"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        1.5HP
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {has15HpAc && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="ac_15hp_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="ac_15hp_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          {/* option */}
                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_2hp_ac"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        2HP
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {has2HpAc && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="ac_2hp_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="ac_2hp_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_2_5hp_ac"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        2.5HP
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {has25HpAc && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="ac_2_5hp_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="ac_2_5hp_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_3hp_ac"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        3HP
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {has3HpAc && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="ac_3hp_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="ac_3hp_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {/* microwave */}
                    <div>
                      <FormField
                        control={form.control}
                        name="has_microwave"
                        render={({ field }) => (
                          <FormItem className="mt-1.5 w-full border-0 border-none flex items-start flex-col p-0">
                            <FormLabel className="text-white text-left">
                              Want to use Microwave?
                            </FormLabel>
                            <Select
                              onValueChange={(value) => {
                                field.onChange(value);
                                // setUseNationalGrid(value);
                              }}
                              defaultValue={field.value}
                              value={field.value}
                            >
                              <FormControl className="bg-[#DEEDCC] w-full">
                                <SelectTrigger>
                                  <SelectValue placeholder="Select" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                <SelectItem value="Yes">Yes</SelectItem>
                                <SelectItem value="No">No</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      {/* options microwave */}
                      {hasMicrowave === "Yes" && (
                        <div className="mt-1">
                          {/* option 1 */}
                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_small_microwave"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        Table Top
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasSmallMicrowave && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="small_microwave_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="small_microwave_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          {/* option2  */}

                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_big_microwave"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        Big Microwave
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasBigMicrowave && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="big_microwave_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="big_microwave_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          {/* option */}
                        </div>
                      )}
                    </div>

                    {/* heaters */}
                    <div>
                      <FormField
                        control={form.control}
                        name="has_heater"
                        render={({ field }) => (
                          <FormItem className="mt-1.5 w-full border-0 border-none flex items-start flex-col p-0">
                            <FormLabel className="text-white text-left">
                              Want to use Heaters?
                            </FormLabel>
                            <Select
                              onValueChange={(value) => {
                                field.onChange(value);
                                // setUseNationalGrid(value);
                              }}
                              defaultValue={field.value}
                              value={field.value}
                            >
                              <FormControl className="bg-[#DEEDCC] w-full">
                                <SelectTrigger>
                                  <SelectValue placeholder="Select" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                <SelectItem value="Yes">Yes</SelectItem>
                                <SelectItem value="No">No</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      {/* options hetaer ac */}
                      {hasHeater === "Yes" && (
                        <div className="mt-1">
                          {/* option 1 */}
                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_heater_1000w"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        1000W
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasHeater1000 && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="heater_1000w_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="heater_1000w_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          {/* option2  */}

                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_heater_1500w"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        1500W
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasHeater1500 && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="heater_1500w_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="heater_1500w_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          {/* option */}

                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_heater_2000w"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        2000W
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasHeater2000 && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="heater_2000w_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="heater_2000w_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    {/* pressing iron */}
                    <div>
                      <FormField
                        control={form.control}
                        name="has_pressing_iron"
                        render={({ field }) => (
                          <FormItem className="mt-1.5 w-full border-0 border-none flex items-start flex-col p-0">
                            <FormLabel className="text-white text-left">
                              Want to use Pressing iron?{" "}
                            </FormLabel>
                            <Select
                              onValueChange={(value) => {
                                field.onChange(value);
                                // setUseNationalGrid(value);
                              }}
                              defaultValue={field.value}
                              value={field.value}
                            >
                              <FormControl className="bg-[#DEEDCC] w-full">
                                <SelectTrigger>
                                  <SelectValue placeholder="Select" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                <SelectItem value="Yes">Yes</SelectItem>
                                <SelectItem value="No">No</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      {/* options iron ac */}
                      {hasPressingIron === "Yes" && (
                        <div className="mt-1">
                          {/* option 1 */}
                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_small_pressing_iron"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        Domestic Iron
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasSmallPressingIron && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="small_pressing_iron_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="small_pressing_iron_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          {/* option2  */}

                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_big_pressing_iron"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        Industrial Iron
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasBigPressingIron && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="big_pressing_iron_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="big_pressing_iron_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          {/* option */}
                        </div>
                      )}
                    </div>

                    {/* sumo pump */}
                    <div>
                      <FormField
                        control={form.control}
                        name="has_sumo_pump"
                        render={({ field }) => (
                          <FormItem className="mt-1.5 w-full border-0 border-none flex items-start flex-col p-0">
                            <FormLabel className="text-white text-left">
                              Want to use SUMO/Water Pump?
                            </FormLabel>
                            <Select
                              onValueChange={(value) => {
                                field.onChange(value);
                                // setUseNationalGrid(value);
                              }}
                              defaultValue={field.value}
                              value={field.value}
                            >
                              <FormControl className="bg-[#DEEDCC] w-full">
                                <SelectTrigger>
                                  <SelectValue placeholder="Select" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                <SelectItem value="Yes">Yes</SelectItem>
                                <SelectItem value="No">No</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      {/* options inverter ac */}
                      {hasSumoPump === "Yes" && (
                        <div className="mt-1">
                          {/* option 1 */}
                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_sumo_1hp"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        1HP
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasSumo1Hp && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="sumo_1hp_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="sumo_1hp_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          {/* option2  */}

                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_sumo_1_5hp"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        1.5HP
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasSumo15Hp && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="sumo_1_5hp_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="sumo_1_5hp_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          {/* option */}
                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_sumo_2hp"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        2HP
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasSumo2Hp && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="sumo_2hp_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="sumo_2hp_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_sumo_2_5hp"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        2.5HP
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasSumo25Hp && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="sumo_2_5hp_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="sumo_2_5hp_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="w-full">
                            <div className="grid grid-cols-3 items-center w-full">
                              <div className="col-span-2 w-full">
                                <FormField
                                  control={form.control}
                                  name="has_sumo_3hp"
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-center justify-between">
                                      <FormLabel className="text-white text-base">
                                        3HP
                                      </FormLabel>

                                      <FormControl>
                                        <Switch
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>
                            </div>

                            {hasSumo3Hp && (
                              <div className="grid grid-cols-2 w-full">
                                <div>
                                  <FormField
                                    control={form.control}
                                    name="sumo_3hp_qty"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Quantity
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="w-full max-w-[65px]  bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>

                                <div>
                                  <FormField
                                    control={form.control}
                                    name="sumo_3hp_avhours"
                                    render={({ field }) => (
                                      <FormItem className="flex items-center space-x-3">
                                        <FormLabel className="text-white text-base">
                                          Daily usage
                                        </FormLabel>

                                        <FormControl>
                                          <Input
                                            {...field}
                                            className="max-w-[65px] bg-[#DEEDCC]"
                                            type="number"
                                            min="1"
                                            placeholder="1 "
                                          />
                                        </FormControl>
                                        <FormLabel className="text-white text-base">
                                          hrs
                                        </FormLabel>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </form>
            </Form>
          </div>
          <div className="w-full md:w-[70%] static md:sticky top-0">
            <div className="bg-[#7F927F50] w-full p-4  rounded-3xl overflow-hidden">
              {!packageData ? (
                <div className="w-full flex flex-col items-center justify-center space-y-4 p-3">
                  <div className="w-fit">
                    <img
                      loading="eager"
                      src={imagesUrl.Logo}
                      alt="logo"
                      height={15}
                      width={123}
                    />
                  </div>

                  <div className="w-[169px] h-[155px] relative">
                    <img
                      loading="eager"
                      src={imagesUrl.EmptyOrder}
                      alt="logo"
                      className="h-full w-full"
                    />
                  </div>

                  <div className="flex flex-col space-y-2">
                    <p className="font-medium text-[19px] leading-[22.8px] text-center text-white">
                      Let&apos;s Meet Your Power Needs
                    </p>
                    <p className="text-[15px] leading-[18px] text-center text-[#CCCCCC] max-w-md">
                      Tell us about your power need and we will recommend the
                      perfect package for you.
                    </p>
                  </div>
                </div>
              ) : (
                <div className=" flex flex-col space-y-3">
                  <div className=" w-full bg-white/10 rounded-lg p-2 flex flex-col space-y-3">
                    <div className="flex flex-col w-full">
                      <div className="border-b pb-2 border-[#808080]">
                        <p className="text-white text-2xl text-left">
                          Calculated Energy
                        </p>
                      </div>
                      <div className="flex flex-col space-y-3  mt-2 w-full">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-1">
                            <img
                              src={imagesUrl.Battery}
                              className="w-[48px] h-[48px] rounded-md"
                              alt="battery"
                              aria-hidden="true"
                            />
                            <p className="text-[19px] text-white">Battery</p>
                          </div>
                          <div>
                            <p className="text-[19px] text-white font-medium leading-[22.8px]">
                              {`${packageData?.user_calculated_energy?.recommended_battery_size.toFixed(
                                2
                              )} AH`}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-1">
                            <img
                              src={imagesUrl.Panel}
                              className="w-[48px] h-[48px] rounded-md"
                              alt="battery"
                              aria-hidden="true"
                            />
                            <p className="text-[19px] text-white">Panel</p>
                          </div>
                          <div>
                            <p className="text-[19px] text-white font-medium leading-[22.8px]">
                              {`${packageData?.user_calculated_energy?.recommended_panel_size.toFixed(
                                2
                              )} WATT`}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-1">
                            <img
                              src={imagesUrl.Inverter}
                              className="w-[48px] h-[48px] rounded-md"
                              alt="battery"
                              aria-hidden="true"
                            />
                            <p className="text-[19px] text-white">Inverter</p>
                          </div>
                          <div>
                            <p className="text-[19px] text-white font-medium leading-[22.8px]">
                              {`${packageData?.user_calculated_energy?.min_recommended_inverter_size.toFixed(
                                2
                              )} KVA`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {(!packageData?.high_package &&
                    !packageData?.lower_package &&
                    !packageData?.recomended_package) ||
                  selectedBuildingType === "business" ? (
                    <div className="flex flex-col w-full">
                      <div className="w-full bg-[#2B2B2B] p-2 rounded-lg">
                        <p className="text-white text-center">
                          No available Package yet, for your energy need.
                        </p>
                        <p className="mt-1 text-[#cccccc] text-center text-[14px]">
                          Please contact support to help create a suitable
                          package for your Energy need.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="flex flex-col w-full">
                        <div className="w-full bg-[#2B2B2B] p-2 rounded-lg">
                          <div className="border-b pb-2 border-[#808080] flex w-full items-center justify-between">
                            <p className="text-white text-2xl text-left">
                              Recommended Package
                            </p>
                            <img
                              src={imagesUrl.MedalStar}
                              alt="medal star"
                              aria-hidden="true"
                              className="w-6 h-6"
                            />
                          </div>

                          {packageData?.recomended_package !== null ? (
                            <div className="flex flex-col w-full ">
                              <div className="flex py-2 items-center justify-between w-full">
                                <div className=" flex flex-col gap-y-2 justify-between items-start">
                                  <p className="text-[19px] text-white text-left">
                                    {packageData?.recomended_package
                                      ?.package_name === undefined ? (
                                      <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
                                    ) : (
                                      `${packageData?.recomended_package?.package_name} `
                                    )}
                                  </p>
                                  <p className="text-white font-bold text-[24px]">
                                    {`${formatPrice(
                                      packageData?.recomended_package
                                        ?.total_item_sales_price ?? 0
                                    )} `}
                                  </p>
                                  {/* <p className="text-[#3EB555] font-medium text-[14px] line-through">
                                N5,906,000
                              </p> */}
                                </div>
                                <div className="h-full flex flex-col gap-y-2 justify-between">
                                  <Button
                                    onClick={() => {
                                      if (installerId) {
                                        navigate("/select-payment-plan");
                                      } else {
                                        navigate("/search/installers");
                                      }
                                      setPackageId(
                                        packageData?.recomended_package?.uniq_id
                                      );
                                    }}
                                    className="text-black bg-[#3EB555] hover:bg-[#359C4A]"
                                  >
                                    Order Now
                                  </Button>

                                  <PackageDetails
                                    uniq_id={
                                      packageData?.recomended_package?.uniq_id
                                    }
                                  >
                                    <Button className="text-[#AAAAAA] border bg-[transparent] border-[#3EB555] hover:bg-transparent">
                                      View Details
                                    </Button>
                                  </PackageDetails>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <p className="text-sm text-[#cccccc] my-2">
                              Please contact support to create a recommended
                              package for your energy need
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="flex flex-col w-full">
                        <div className="w-full bg-white/10  p-2 rounded-lg">
                          <div className="border-b pb-2 border-[#808080] flex w-full items-center justify-between">
                            <p className="text-white text-2xl text-left">
                              Higher Package
                            </p>
                          </div>

                          {packageData?.high_package !== null ? (
                            <div className="flex flex-col w-full ">
                              <div className="flex py-2 items-center justify-between w-full">
                                <div className=" flex flex-col gap-y-2 justify-between items-start">
                                  <p className="text-[19px] text-white text-left">
                                    {packageData?.high_package?.package_name ===
                                    undefined ? (
                                      <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
                                    ) : (
                                      `${packageData?.high_package?.package_name} `
                                    )}
                                  </p>
                                  <p className="text-white font-bold text-[24px]">
                                    {`${formatPrice(
                                      packageData?.high_package
                                        ?.total_item_sales_price ?? 0
                                    )} `}
                                  </p>
                                  {/* <p className="text-[#3EB555] font-medium text-[14px] line-through">
                              N5,906,000
                            </p> */}
                                </div>
                                <div className="h-full flex flex-col gap-y-2 justify-between">
                                  <Button
                                    onClick={() => {
                                      if (installerId) {
                                        navigate("/select-payment-plan");
                                      } else {
                                        navigate("/search/installers");
                                      }
                                      setPackageId(
                                        packageData?.high_package?.uniq_id
                                      );
                                    }}
                                    className="text-black bg-[#3EB555] hover:bg-[#359C4A]"
                                  >
                                    Order Now
                                  </Button>
                                  <PackageDetails
                                    uniq_id={packageData?.high_package?.uniq_id}
                                  >
                                    <Button className="text-[#AAAAAA] border bg-[transparent] border-[#3EB555] hover:bg-transparent">
                                      View Details
                                    </Button>
                                  </PackageDetails>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <p className="text-sm text-[#cccccc] my-2">
                              Please contact support to create a higher package
                              for your energy need
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col w-full">
                        <div className="w-full bg-white/10  p-2 rounded-lg">
                          <div className="border-b pb-2 border-[#808080] flex w-full items-center justify-between">
                            <p className="text-white text-2xl text-left">
                              Lower Package
                            </p>
                          </div>

                          {packageData?.lower_package !== null ? (
                            <div className="flex flex-col w-full ">
                              <div className="flex py-2 items-center justify-between w-full">
                                <div className=" flex flex-col gap-y-2 justify-between items-start">
                                  <p className="text-[19px] text-white text-left">
                                    {packageData?.lower_package
                                      ?.package_name === undefined ? (
                                      <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
                                    ) : (
                                      `${packageData?.lower_package?.package_name} `
                                    )}
                                  </p>
                                  <p className="text-white font-bold text-[24px]">
                                    {`${formatPrice(
                                      packageData?.lower_package
                                        ?.total_item_sales_price ?? 0
                                    )} `}{" "}
                                  </p>
                                </div>
                                <div className="h-full flex flex-col gap-y-2 justify-between">
                                  <Button
                                    onClick={() => {
                                      if (installerId) {
                                        navigate("/select-payment-plan");
                                      } else {
                                        navigate("/search/installers");
                                      }
                                      setPackageId(
                                        packageData?.lower_package?.uniq_id
                                      );
                                    }}
                                    className="text-black bg-[#3EB555] hover:bg-[#359C4A]"
                                  >
                                    Order Now
                                  </Button>
                                  <PackageDetails
                                    uniq_id={
                                      packageData?.lower_package?.uniq_id
                                    }
                                  >
                                    <Button className="text-[#AAAAAA] border bg-[transparent] border-[#3EB555] hover:bg-transparent">
                                      View Details
                                    </Button>
                                  </PackageDetails>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <p className="text-sm text-[#cccccc] my-2">
                              Please contact support to create a lower package
                              for your energy need
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </MaxWidthWrapper>
  );
};

export default PlanSelector;
