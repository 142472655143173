import React from "react";
import NavFilled from "../../components/nav-filled";
import HowItWork from "./components/how-it-work";
import Footer from "../../components/footer";
import WhyUs from "./components/why-us";
import MaxWidthWrapper from "../../components/max-width-wrapper";

const HowItWorksPage = () => {
  return (
    <div className="h-full w-full">
      <NavFilled />

      <MaxWidthWrapper>
        <HowItWork />
      </MaxWidthWrapper>

      <WhyUs />

      <Footer />
    </div>
  );
};

export default HowItWorksPage;
