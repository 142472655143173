import { Button } from "../../components/ui/button";
import NavTransparent from "../../components/nav-transparent";
import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer";
import { useGetHomeSetupQuery } from "../../store/ordersApiSlice";
import { Loader2 } from "lucide-react";

const HomePage = () => {
  const { data, isLoading, refetch } = useGetHomeSetupQuery();
  const homeSetup = data?.data;

  const navigate = useNavigate();
  return (
    <div
      className="flex flex-col min-h-screen" // Ensure the height is at least 100% of the viewport
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url('/banner-image.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <NavTransparent />

      <div className="flex-grow flex flex-col items-center justify-start w-full py-[15rem]">
        {" "}
        {/* Adjusted py and mt */}
        <h1 className="text-2xl md:text-5xl font-semibold  text-center   text-white">
          Your modern gateway to Solar Energy
        </h1>
        <p className="text-[#cccccc] mt-3 text-center text-lg max-w-sm md:max-w-md">
          Design your solar energy system, connect with installers, enjoy
          reliability.
        </p>
        <div className="mt-3">
          <Button
            onClick={() => navigate("/order-a-system")}
            size={"lg"}
            className="bg-[#3EB555] hover:bg-[#359C4A] text-black"
          >
            Order Now
          </Button>
        </div>
      </div>

      {/* Optional: Add more padding or margin to create more scrollable space */}
      <div className="mt-auto py-10">
        {/* This can ensure more space is added at the bottom */}
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
