import { useGetInstallerQuery } from "../../../store/installersApiSlice";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import imagesUrl from "../../../assets/images/imagesUrl";
import NavbarClient from "./components/navbar";
import PageBanner from "./components/banner";
import MaxWidthWrapper from "./components/max-width-wrapper";
import AboutUs from "./components/about-us";
import Footer from "./components/footer";
import OurExpertise from "./components/our-expertise";
import ContactUs from "./components/contact-us";
import { Phone } from "lucide-react";
import Installations from "./components/installations";

const InstallerDetailPage = () => {
  const { installerId } = useParams<{ installerId: string }>();

  const { data, isLoading, refetch } = useGetInstallerQuery(
    installerId ? { uniq_id: installerId } : skipToken
  );

  const installer = data?.data;

  console.log(installer?.installations, "installer");

  return (
    <div className="">
      <NavbarClient
        installerName={installer?.name ?? ""}
        image={installer?.image ?? imagesUrl.DefaultImage}
      />
      <div className="mb-3 md:mb-10 h-full mx-auto flex flex-col items-center w-full  ">
        <PageBanner
          installerName={installer?.name ?? ""}
          about={installer?.about ?? "Not Available"}
        />
      </div>

      <AboutUs
        phone={installer?.phone ?? "Not Available"}
        about={installer?.about ?? "Not Available"}
        installerName={installer?.name ?? ""}
      />

      <OurExpertise expertise={installer?.expertise ?? []} />

      <Installations
        installations={
          Array.isArray(installer?.installations)
            ? installer?.installations
            : []
        }
      />

      <ContactUs
        email={installer?.email ?? "Not Available"}
        phone={installer?.phone ?? "Not Available"}
        address={installer?.address ?? "Not Available"}
      />

      <Footer
        image={installer?.image ?? imagesUrl.DefaultImage}
        phone={installer?.phone ?? "Not Available"}
        facebook={installer?.facebook ?? ""}
        instagram={installer?.instagram ?? ""}
        youtube={installer?.youtube ?? ""}
        linkedin={installer?.linkedin ?? ""}
        twitter={installer?.twitter ?? ""}
        whatsapp={installer?.whatsapp ?? ""}
        about={installer?.about ?? "Not Available"}
        installerName={installer?.name ?? ""}
      />
    </div>
  );
};

export default InstallerDetailPage;
