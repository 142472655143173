import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import type SwiperType from "swiper";
import { Pagination, Autoplay } from "swiper/modules";
import { ChevronLeft, ChevronRight, Search } from "lucide-react";
import "swiper/css";

import "swiper/css/pagination";
import { cn } from "../../../../lib/utils";
import { bannerImagesUrl } from "../../../../assets/images/imagesUrl";
import { useNavigate, useParams } from "react-router-dom";
import { IdState, useIdStore } from "../../../../store/customer-store";
import { Button } from "../../../../components/ui/button";

const PageBanner = ({
  installerName,
  about,
}: {
  installerName: string;
  about: string;
}) => {
  const [swiper, setSwiper] = useState<null | SwiperType>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const navigate = useNavigate();
  const setInstallerId = useIdStore((state: IdState) => state.setInstallerId);
  const packageId = useIdStore((state: IdState) => state.readPackageId());

  const { installerId } = useParams<{ installerId: string }>();

  const handleOrderPackage = () => {
    setInstallerId(installerId ?? "");

    if (packageId) {
      navigate("/select-payment-plan");
    } else {
      navigate("/order-a-system");
    }
  };

  const [slideConfig, setSlideConfig] = useState({
    isBeginning: true,
    isEnd: activeIndex === (bannerImagesUrl.length ?? 0) - 1,
  });

  useEffect(() => {
    swiper?.on("slideChange", ({ activeIndex }: { activeIndex: number }) => {
      setActiveIndex(activeIndex);
      setSlideConfig({
        isBeginning: activeIndex === 0,
        isEnd: activeIndex === (bannerImagesUrl.length ?? 0) - 1,
      });
    });
  }, [swiper, bannerImagesUrl]);

  const activeStyles =
    "active:scale-[0.97] grid opacity-100 hover:scale-105 absolute top-1/2 -translate-y-1/2 aspect-square h-8 w-8 z-50 place-items-center rounded-full border-2 bg-white border-zinc-300";
  const inactiveStyles = "hidden text-gray-400";

  return (
    <div
      id="page-banner"
      className="relative w-full grid h-[15rem] md:h-[29rem]  grid-cols-12 gap-y-8 sm:gap-x-4"
    >
      <div className="col-span-12 group h-full w-full relative bg-zinc-100 aspect-square overflow-hidden ">
        <div className="absolute z-10 inset-0 bg-[#0d001a] opacity-60"></div>

        <div className="w-full px-8 md:px-0  md:max-w-lg z-30 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center flex flex-col items-center space-y-4">
          <h2 className="text-white text-2xl  md:text-5xl font-semibold">
            {installerName}
          </h2>

          <p className="text-[#cccccc] text-lg">
            We provide solar installations to keep you going
          </p>
          <div className="  ">
            <Button
              onClick={handleOrderPackage}
              size={"lg"}
              className="bg-[#3EB555] hover:bg-[#359C4A] text-black"
            >
              Order Now
            </Button>
          </div>
        </div>

        <div className="absolute  z-20 inset-0 opacity-0 group-hover:opacity-100 transition">
          <button
            onClick={(e) => {
              e.preventDefault();
              swiper?.slideNext();
            }}
            className={cn(activeStyles, "right-3 transition", {
              [inactiveStyles]: slideConfig.isEnd,
              "hover:bg-primary-300 text-primary-800 opacity-100":
                !slideConfig.isEnd,
            })}
            aria-label="next-image"
          >
            <ChevronRight className="h-4 w-4 text-zinc-700" />
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              swiper?.slidePrev();
            }}
            className={cn(activeStyles, "left-3 transition", {
              [inactiveStyles]: slideConfig.isBeginning,
              "hover:bg-primary-300 text-primary-800 opacity-100":
                !slideConfig.isBeginning,
            })}
            aria-label="prev-image"
          >
            <ChevronLeft className="h-4 w-4 text-zinc-700" />
          </button>
        </div>

        <Swiper
          pagination={{
            renderBullet: (_: any, className: string) => {
              return `<span class="rounded-full !bg-white !text-white transition ${className}"></span>`;
            },
          }}
          onSwiper={(swiper: SwiperType) => setSwiper(swiper)}
          spaceBetween={50}
          slidesPerView={1}
          modules={[Pagination, Autoplay]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          className="h-full w-full"
        >
          {bannerImagesUrl.map((url, idx) => (
            <SwiperSlide
              key={idx}
              className=" aspect-w-1 aspect-h-1 relative -z-10 h-full w-full"
            >
              <img
                loading="eager"
                className="-z-10 h-full w-full object-cover object-center"
                src={url}
                alt="banner"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default PageBanner;
